<template>
    <div>
        <cookie-law theme="dark-lime">
            <v-layout slot-scope="props">
                <div>
                    Tento web a e-shop používá ke svému provozu, zlepšování funkčnosti a analýze návštěvnosti tzv. soubory cookies. Používáním tohoto webu a e-shopu s tím souhlasíte. Více informací naleznete&nbsp;&nbsp;
                    <router-link to="/privacy" class="success-text" v-if="$vuetify.breakpoint.smAndUp">zde</router-link>
                  <span class="text-decoration-underline success--text" @click="dialog = true" v-else>zde</span>.&nbsp;
                  <v-btn style="margin-right: 30px;" color="primary" @click="props.accept">Rozumím</v-btn>
                </div>
            </v-layout>
        </cookie-law>
        <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                        dark
                        color="primary"
                >
                    <v-btn
                            color="black"
                            icon
                            @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title class="black--text">Více informací o cookies</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text class="black--text">
                    <h2 class="mb-1 mt-2">Co je to cookie?</h2>
                    <p>Jako cookies, resp. cookie, se v protokolu HTTP označuje textový soubor – malé množství dat, která servery pošlou prohlížeči. Ten je uloží na počítači uživatele.
                        Při každé další návštěvě stránek pak prohlížeč tato data posílá zpět serveru.
                        Cookies běžně slouží k rozlišování jednotlivých uživatelů, ukládá se do nich typicky obsah "nákupního košíku" v e-shopu, uživatelské předvolby a další.
                        Technicky jde o sérii kódů, podle kterých prohlížeč shromažďuje a následně odesílá informace o vašem chování zpět na příslušný server.
                        Cookies obsahují informaci o tom, jak dlouho je má prohlížeč uchovávat (od jednorázových až po několikaleté).
                        Cookies nejsou přímo vázány na vaše jméno ani e-mailovou adresu, často ale obsahují vaše přihlašovací údaje do systému. Jsou společné pro jednu instalaci prohlížeče. Cookies nejsou nijak zabezpečeny, a to včetně shromážděných údajů.</p>
                    <h2 class="mb-1">Jaké cookies používáme?</h2>
                    <p>Používáme cookies třetích stran, konkrétně Google Analytics pro analýzu návštěvnosti.
                        Tyto cookies jsou řízeny třetími stranami a nemáme přístup ke čtení nebo zápisu těchto dat.
                        O podmínkách užití Google Analytics se můžete dočíst <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage" target="_blank">zde</a></p>
                    <h2 class="mb-1">Co můžete?</h2>
                    <p>Soubory cookies můžete odmítnout nebo v případě potřeby vymazat. Pokyny, jak to udělat, najdete v sekci "Nápověda" vašeho webového prohlížeče.</p>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import CookieLaw from 'vue-cookie-law'

    export default {
        components: { CookieLaw },
        data() {
            return {
                dialog: false
            }
        }
    }
</script>

<style>
    a.success-text {
        color: #8BC34A !important;
    }
    .Cookie {
        z-index: 201 !important;
    }
</style>
