<template>
  <div>
    <v-container grid-list-md class="lectors" id="lectors">
      <v-row justify="centers">
        <SectionTitle title="Naši lektoři" subtitle="Jsme hrdí, že Vám můžeme nabídnout širokou škálu instruktorů, kteří jsou nejen profesionály na svém místě, ale také velmi dobře rozumí potřebám našich klientů." />
        <v-col cols="12" class="custom-slick lectors">
          <VueSlickCarousel v-bind="settings" v-if="lectors.length > 0">
            <div v-for="(lector,index) in lectors" :key="index" class="pa-2">
              <div style="position:relative;" class="relative-box">
                <img :src="lector.image" style="object-fit: contain;" />
                <div class="name">
                  <div class="heading">
                    <h4 class="text-h5 font-weight-bold">{{ lector.name }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </VueSlickCarousel>
        </v-col>
      </v-row>
    </v-container>
    <v-navigation-drawer
        v-model="drawer"
        right
        fixed
        temporary
        width="490"
    >
      <v-toolbar dense flat v-if="$vuetify.breakpoint.xsOnly">
        <v-spacer />
        <v-btn icon @click="drawer = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import SectionTitle from "@/components/HomePage/SectionTitle";
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  name: 'Lectors',
  components: {
    SectionTitle,
    VueSlickCarousel
  },
  data() {
    return {
      drawer: null,
      currentLector: null,
      lectors: [
        {name: 'Olga Huspeková', sport: 'Pilates', image: require('../../assets/lectors/all/1.jpg')},
        {name: 'Anna Karmazínová', sport: 'Pilates', image: require('../../assets/lectors/all/2.jpg')},
        {name: 'Markéta Léblová', sport: 'Pilates', image: require('../../assets/lectors/all/3.jpg')},
        {name: 'Martina Podborská', sport: 'Pilates', image: require('../../assets/lectors/all/4.jpg')},
        {name: 'Míša Malá', sport: 'Pilates pro těhotné, po porodu', image: require('../../assets/lectors/all/5.jpg')},
        {name: 'Hana Soukupová', sport: 'Bodyform', image: require('../../assets/lectors/all/6.jpg')},
        {name: 'Dana Šachová', sport: 'Jógalates', image: require('../../assets/lectors/all/7.jpg')},
        {name: 'Daniel Hampel', sport: 'Pilates', image: require('../../assets/lectors/all/8.jpg')},
        {name: 'Aneta Šmerdová', sport: 'Pilates', image: require('../../assets/lectors/all/9.jpg')},
        {name: 'Adéla Ježková', sport: 'Zumba', image: require('../../assets/lectors/all/10.jpg')},
        {name: 'Elen Kruzhkova', sport: 'Pilates', image: require('../../assets/lectors/all/11.jpg')},
        {name: 'Katka Machová', sport: 'Zdravá záda', image: require('../../assets/lectors/all/12.jpg')},
        {name: 'Eva Žlutířová', sport: 'Pilates', image: require('../../assets/lectors/all/13.jpg')},
        {name: 'Tereza Sebesta', sport: 'Jóga', image: require('../../assets/lectors/all/14.jpg')},
        {name: 'Mikuláš Pýcha', sport: 'Pilates', image: require('../../assets/lectors/all/16.jpg')},
        {name: 'Janetta Kůstková', sport: 'Pilates', image: require('../../assets/lectors/all/17.jpg')},
        {name: 'Míša Sochová', sport: 'Pilates', image: require('../../assets/lectors/all/18.jpg')},
        {name: 'Alexandra Hájková', sport: 'Pilates', image: require('../../assets/lectors/all/19.jpg')},
        {name: 'Jana Leštinová', sport: 'Pilates', image: require('../../assets/lectors/all/23.jpg')},
      ],
      settings: {
        "dots": true,
        "autoplay": false,
        "autoplayTimeout": 6000,
        "autoplayHoverPause": true,
        "focusOnSelect": true,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 5,
        "slidesToScroll": 5,
        "touchThreshold": 5,
        "lazyLoad": "ondemand",
        "arrows": false,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
              "dots": false
            }
          }
        ]
      }
    }
  },
  methods: {
    showLector(lector) {
      this.currentLector = lector;
      this.drawer = true;
    }
  }
}
</script>

<style lang="scss" scoped>

.relative-box {
  position: relative;
}

.custom-slick.lectors {
  /*max-width: 920px;*/
}

.custom-slick.lectors .slick-slide {
  margin: 0 12px;
  overflow: hidden;
  max-width: 300px;
}

.custom-slick.lectors .slick-slide .name .heading {
  position: absolute;
  bottom: 16px;
  text-align: center;
  width: 100%;
}

.custom-slick.lectors .slick-slide .name {
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0), #D22C6F);
  width: 100%;
  min-height: 30%;
  color: #fff;
  text-align: center;
}

.slick-slide > div > div:focus {
  outline: none;
}
.cursor-pointer {
  cursor: pointer;
}
.flex-card {
  display: flex;
  border-radius: 24px !important;
  flex-direction: column;
}
.lectors {
  .v-image {
    border-top-left-radius: 24px !important;
    border-top-right-radius: 24px !important;
  }
  .cursor-pointer:hover {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  }
}
</style>
