<template>
    <v-container grid-list-lg>
        <v-layout row wrap v-if="loadingCategories">
            <v-flex xs12 sm6 md4 v-for="n in 3" :key="n">
                <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-flex>
        </v-layout>

        <v-row>
          <v-col
              v-for="product in filteredProducts" :key="product.id" cols="12" sm="6" md="4"
          >
            <v-card class="flex-column-internal fill-height">
              <div class="grow">

                    <v-img
                        :src="getProductImage(product.imageUrl)"
                        class="white--text align-end"
                        height="200px"
                    >
                      <div class="d-flex justify-end align-center fill-height flex-column text-center" style="background: rgba(0,0,0,0.55)">
                        <h4 class="d-flex text-uppercase white--text py-2">{{product.name}}</h4>
                      </div>
                    </v-img>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
</template>

<script>
    import {getProductImage} from '@/Helper'
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: 'Products',
        mixins: [getProductImage],
        computed: {
            ...mapGetters([
                'allProducts',
                'loadingCategories'
            ]),
            filteredProducts() {
                if (!this.search) {
                    return this.allProducts;
                }

                return this.allProducts.filter(post => {
                    return post.name.toLowerCase().includes(this.search.toLowerCase())});
            },
        },
        data() {
            return {
            }
        },
        methods: {
            ...mapActions([
                'addToCart'
            ]),
          getProductTitle(product) {
            return product.split(' | ');
          }
        }
    }
</script>

<style lang="scss">
.flex-column-internal {
  display: flex;
  flex-direction: column;
}
.category-divider {
  width: 100%;
  height: 5px;
}
.product-card-title {
  font-size: 18px;
}
    .cursor-pointer {
        cursor: pointer;
    }

    .v-card__title {
        word-break: break-word;
    }
.v-card--reveal {
  align-items: center;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
