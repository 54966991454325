<template>
    <div>
        <v-overlay :value="checkingCustomer">
            <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: 'PaymentFeedback',
        mounted() {
            let urlParams = window.location.search;

            this.getPaymentFeedback(urlParams);
        },
        computed: {
            ...mapGetters([
                'checkingCustomer'
            ])
        },
        methods: {
            ...mapActions([
                'getPaymentFeedback'
            ])
        }
    }
</script>
