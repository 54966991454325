<template>
  <v-container fluid class="my-6" id="plans">
    <v-container grid-list-lg>
      <v-row class="my-12">
        <SectionTitle v-if="!compact" title="NAŠE ČLENSTVÍ" />
        <v-col cols="12" sm="4" v-for="(item,index) in plans" :key="index" class="mt-sm-0 mt-5">
          <v-card class="fill-height subscription-card" elevation="3">
            <v-card-text class="text-center grow pb-0">
              <h4 class="mt-6 text-h3 font-weight-bold black--text" v-html="item.name" />
              <p class="py-3">
                <span class="old-price">{{ item.amountOld }}</span><br />
                <span class="text-h3 black--text primary--text">
                  {{ item.amountWithVat }}
                </span>
                <br />
                <template v-if="item.amountWithVat !== 'za 1 Kč'">(včetně DPH 21%)</template>
                <template v-if="item.amountWithVat === 'za 1 Kč'">(poté každý měsíc 199,- Kč včetně DPH 21%)</template>
              </p>
              <v-divider></v-divider>
              <p class="py-3 text-left" style="line-height: 2;">
                <span v-for="(paragraph, index) in item.description" :key="index">
                  {{ paragraph }}
                  <br />
                </span>
              </p>
            </v-card-text>
            <v-card-text class="pt-0">
              <v-btn rounded block depressed color="primary" :href="getUrl(item.url)" target="_blank">Koupit</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
  import SectionTitle from "@/components/HomePage/SectionTitle";
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: 'Subscription',
    components: {SectionTitle},
    props: ['compact'],
    data() {
      return {
        loading: false,
        plans: [
          /*{
            name: 'Vyzkoušejte si členství na 7 dní',
            description: [
              'Neomezený počet shlédnutí předtočených videí z naší knihovny videí.',
              'Neomezené využívání týdenních tréninkových plánů a výzev.',
              'Neomezené přihlašování na online živé lekce dle rozvrhu cvičení.',
              '7 dní za 1 kč, poté automatická opakovaná platba po 30 dnech.',
              'Své členství můžete kdykoliv zrušit.'
            ],
            //description: 'Nyní speciální akce s objednáním do 30. září',
            amountWithVat: 'za 1 Kč',
            //amountOld: '1 990 Kč',
            //url: 'https://www.elixiractive.cz/produkt/elixir-online-rocni-jednorazove-clenstvi/'
            url: 'trial'
          },*/
          {
            name: 'Opakované měsíční členství',
            //description: 'Nyní speciální akce s objednáním do 30. září',
            description: [
                'Neomezený počet shlédnutí předtočených videí z naší knihovny videí.',
                'Neomezené využívání týdenních tréninkových plánů a výzev.',
                'Neomezené přihlašování na online živé lekce dle rozvrhu cvičení.',
                'Automatická opakovaná platba po 30 dnech.',
                'Své členství můžete kdykoliv zrušit.'
            ],
            amountWithVat: '199 Kč',
            //amountOld: '199 Kč / 1 měsíc',
            //url: 'https://www.elixiractive.cz/produkt/elixir-online-mesicni-jednorazove-clenstvi/'
            url: 'mesic'
          },
          {
            name: 'Opakované čtvrtletní členství',
            description: [
              'Neomezený počet shlédnutí předtočených videí z naší knihovny videí.',
              'Neomezené využívání týdenních tréninkových plánů a výzev.',
              'Neomezené přihlašování na online živé lekce dle rozvrhu cvičení.',
              'Automatická opakovaná platba po 90 dnech.',
              'Své členství můžete kdykoliv zrušit.'
            ],
            //description: 'Nyní speciální akce s objednáním do 30. září',
            amountWithVat: '549 Kč',
            //amountOld: '549 Kč',
            //url: 'https://www.elixiractive.cz/produkt/elixir-online-ctvrtletni-jednorazove-clenstvi/',
            url: 'ctvrtleti'
          },
          {
            name: 'Opakované roční členství',
            description: [
              'Neomezený počet shlédnutí předtočených videí z naší knihovny videí.',
              'Neomezené využívání týdenních tréninkových plánů a výzev.',
              'Neomezené přihlašování na online živé lekce dle rozvrhu cvičení.',
              'Automatická opakovaná platba po 365 dnech.',
              'Své členství můžete kdykoliv zrušit.'
            ],
            //description: 'Nyní speciální akce s objednáním do 30. září',
            amountWithVat: '1 990 Kč',
            //amountOld: '1 990 Kč',
            //url: 'https://www.elixiractive.cz/produkt/elixir-online-rocni-jednorazove-clenstvi/'
            url: 'rok'
          }
        ]
      }
    },
    mounted() {

    },
    methods: {
      ...mapActions([
        'addToCart'
      ]),
      onLoadSuccess(response) {
        this.loading = false;
        this.plans = response.data;
      },
      getUrl(period) {
        if(this.user) {
          return '/subscription/' + period;
        } else {
          return '/vytvoreni-uctu/' + period;
        }
      },
    },
    computed: {
      ...mapGetters([
        'cartProducts',
        'user',
        'loginModal',
        'passwordModal'
      ]),
    }
  }
</script>

<style scoped>
.old-price {
  @apply line-through;
}

.v-application .text-h3 {
  font-size: 2.4rem !important;
}

.subscription-card {
  border-radius: 24px !important;
  display: flex;flex-direction: column;
}
</style>
