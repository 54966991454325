<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" v-if="loading" class="text-center">
        <v-progress-circular indeterminate color="primary" />
        <div class="mt-3">
          Nahrávám videa
        </div>
      </v-col>
      <v-col cols="12" class="pb-0" v-if="!loading">
        <h2 class="mb-3 text-uppercase">Předplacený online obsah</h2>
      </v-col>
      <v-col cols="12" v-if="!loading && videos.length === 0">
        Doposud nebyla nalezena žádná videa.
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-container fluid class="pt-0">
          <v-row>
            <v-col cols="12" class="text-right py-0">
              <v-btn icon @click="prev" :disabled="prevDisabled()">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn icon @click="next" :disabled="nextDisabled()">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pa-0">
              <swiper ref="swiper" class="swiper" :options="swiperOptions">
                <swiper-slide v-for="product in videos" :key="product.id">

                  <v-card
                      flat
                      height="240"
                      @click="openDialog(product.url)"
                      color="transparent"
                      class="text-left"
                  >
                    <v-img
                        height="180"
                        class="flex-card elevation-3"
                        :src="getProductImage(product.imageUrl)"
                    >
                    </v-img>
                    <v-list color="transparent" class="text-uppercase font-weight-medium">
                      <v-list-item class="pl-0">
                        <v-list-item-title>
                          {{product.name}}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </swiper-slide>
              </swiper>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card tile>
        <v-toolbar
            color="transparent"
        >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                icon
                @click="closeDialog"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <vue-vimeo-player ref="player" :player-width="undefined" :options="{responsive: true, autoplay: true}" :video-id='dialogVideo'></vue-vimeo-player>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'
  import {getProductImage} from "@/Helper";
  import { vueVimeoPlayer } from 'vue-vimeo-player'
  import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
  import 'swiper/css/swiper.css'

  export default {
    name: 'Videos',
    mounted() {
      this.loadVideos();
      this.swiper = this.$refs.swiper.$swiper;
    },
    mixins: [getProductImage],
    components: { vueVimeoPlayer,  Swiper,
      SwiperSlide },
    computed: {
      ...mapGetters([
          'user'
      ]),
    },
    directives: {
      swiper: directive
    },
    data() {
      return {
        videos: [],
        loading: false,
        dialog: false,
        dialogVideo: null,
        swiper: null,
        swiperOptions: {
          slidesPerView: 4,
          spaceBetween: 30
        }
      }
    },
    methods: {
      prevDisabled() {
        return this.$refs.swiper ? this.$refs.swiper.$swiper.progress < 0.1 : false;
      },
      nextDisabled() {
        return this.$refs.swiper ? this.$refs.swiper.$swiper.progress > 0.9 : false;
      },
      openDialog(url) {
        this.dialogVideo = url;
        this.dialog = true;
      },
      closeDialog() {
        this.dialog = false;
        this.$refs.player.pause();
      },
      loadVideos() {
        this.loading = true;
        window.axios.get(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_ACCOUNT + '/onlinevideo', {
          headers: {
            Authorization: window.auth.getAuthHeader()
          }
        }).then(response => {
        this.onLoadVideoSuccess(response);
        }).catch(error => {console.log(error)});
      },
      onLoadVideoSuccess(response) {
        this.videos = response.data;
        this.loading = false;
      },
      prev() {
        this.$refs.swiper.$swiper.slidePrev()
      },
      next() {
        this.$refs.swiper.$swiper.slideNext()
      }
    }
  }
</script>

<style>
.swiper-container {
  height: 260px;
  width: 100%;
}

.swiper-slide {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>
