<template>
  <div>

    <Videointro/>

    <!-- Apollo watched Graphql query -->
    <ApolloQuery
        :query='gql => gql`query MyQuery {entries(type: "categories") { id, title } }`' :variables="{ name }">
      <template slot-scope="{ result: { loading, error, data } }">
        <!-- Loading -->
        <div v-if="loading" class="loading apollo">Loading...</div>
        <!-- Error -->
        <div v-else-if="error" class="error apollo">An error occured</div>
        <div v-else-if="data">
          <Videolist :demo="true" v-for="(row, key) in data.entries" :key="key" :order="key" :categories="[row.id]" :heading="row.title"/>
        </div>
      </template>
    </ApolloQuery>
  </div>
</template>


<script>

import {mapGetters, mapActions} from 'vuex'
import Videolist from "@/components/Elixir/VideoList";
import Videointro from "@/components/Elixir/VideoIntro";
import gql from "graphql-tag";

export default {
  name: 'CategoryPage',
  apollo: {
    // Simple query that will update the 'hello' vue property
    entries: gql`query MyQuery {
  entries(type: "categories") {
    id,
    title
  }
}`,
  },
  metaInfo() {
    return {
      title: 'Videa',
    }
  },
  watch: {
    loadingCategories: function (newValue) {
      if (!newValue) {
        this.changeCategory();
      }
    }
  },
  mounted() {
    this.changeCategory();
  },
  components: {
    Videointro,
    Videolist
  },
  computed: {
    ...mapGetters([
      'allProducts',
      'currentCategory',
      'loadingCategories'
    ])
  },
  methods: {
    ...mapActions([
      'changeCategory'
    ])
  }
}
</script>
