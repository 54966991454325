<template>
  <div>
    <div v-if="notCurrentMonth" class="day disabled">
      <span class="number" v-text="day" />
    </div>
    <a v-else @click="selectToday();" href="#" class="day" :class="{
      'selected': isSelectedDay,
      'current': isToday,
    }">
      <span class="number" v-text="day" />
      <div v-if="events.length == 1">
      <div v-for="e in events" :key="e.id" class="lesson">
        <strong v-text="time(e)"/>
        <span v-text="e.title"/>
      </div>
      </div>
      <div class="lesson" v-else-if="events.length > 0">
        <strong v-text="events.length"/> <span>
        lekce tento den
      </span>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  props: {
    selectedDate: {
      type: Date,
      required: true
    },
    date: {
      type: Date,
      required: true
    },
    events: {
      type: Array,
      required: false
    }
  },
  methods: {
    time(e) {
      let mins = parseInt((new Date(e.date)).getMinutes());
      if (mins < 10) mins = '0' + mins;
      return (new Date(e.date)).getHours() + ':' + mins;
    },
    selectToday() {
      this.$parent.selectDate(this.date);
    }
  },
  computed: {
    notCurrentMonth() {
      return this.date.getMonth() !== (new Date()).getMonth();
    },
    day() {
      return this.date.getDate();
    },
    isToday() {
      return this.date.toDateString() === new Date().toDateString();


    },
    isSelectedDay() {
      return this.selectedDate.toDateString() === this.date.toDateString();


    }
  }
}
</script>
<style>
.day {
  @apply text-center block w-full md:border-2 md:border-[#f6eeff] md:rounded-8 md:relative md:h-[124px] relative;
}

.number {
  @apply mx-auto md:absolute md:top-[5px] md:left-[5px] lg:top-[10px] lg:left-[10px] block w-[34px] h-[34px] text-center rounded-full text-black text-[23px] leading-[34px] font-bold;
}

.day.current.selected .number {
  @apply text-white;
}

.lesson {
  @apply md:block md:absolute md:left-[5px] lg:left-[15px] top-[55px] border-l-[3px] pl-5 border-white w-[91px];
}

.lesson strong {
  @apply font-500 text-[12px] lg:text-14 block w-full text-left;
}

.lesson span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @apply text-[12px] block w-full;
}

.day.current .number {
  @apply text-white bg-[#d11a6f];
}

.day.selected {
  @apply border-[#d11a6f];
}

.day.selected .number {
  @apply text-[#d11a6f] border-2 border-[#d11a6f] md:border-none leading-[30px];
}

.day.disabled {
  @apply md:border md:border-[#f6eeff] md:bg-[#f5f5f5];
}
.day.disabled .number {
  @apply text-[#a5a5a5];
}

</style>