<template>
  <v-card>
    <v-toolbar flat height="30px">
      <v-spacer/>
      <v-btn icon small @click="setLoginModal()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-0" v-if="!forgotten">
      <v-form v-model="valid" ref="form" lazy-validation @keyup.native.enter="submit">
        <v-alert
            v-if="error"
            type="error"
            outlined
        >
          {{ error }}
        </v-alert>
        <v-text-field
            name="email"
            label="Váš email"
            type="text"
            v-model="credentials.username"
            :rules="[rules.required, rules.email]"
        ></v-text-field>
        <v-text-field
            name="password"
            label="Heslo" id="password"
            type="password"
            v-model="credentials.password"
            :rules="[rules.required]"
        ></v-text-field>
        <v-checkbox v-model="checked" label="Zapamatovat si mě"></v-checkbox>
        <div class="pt-2">
          <v-btn color="primary" :loading="loading" :disabled="loading" block class="ml-0 font-weight-medium"
                 @click="submit">Přihlásit se
          </v-btn>
        </div>
        <p>&nbsp;</p>
        <p>
          <button @click="forgotten = true;" class="pt-20 underline">Zapomenuté heslo?</button>
        </p>
      </v-form>
    </v-card-text>
    <v-card-text class="pt-0" v-if="forgotten">
      <v-text-field
          name="email"
          label="Váš email"
          type="text"
          v-model="credentials.username"
          :rules="[rules.required, rules.email]"
      ></v-text-field>
      <div class="pt-2">
        <v-btn color="primary" :loading="loading" :disabled="loading" block class="ml-0 font-weight-medium"
               @click="submitPassword">Zaslat nové heslo
        </v-btn>
      </div>
      <p>&nbsp;</p>
      <p>
        <button @click="forgotten = false;" class="pt-20 underline">Znám své heslo. Chci se přihlásit.</button>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>

import {mapActions} from 'vuex'

export default {
  name: 'LoginModal',
  data() {
    return {
      forgotten: false,
      checked: true,
      addProject: null,
      error: '',
      valid: true,
      loading: false,
      credentials: {
        username: '',
        password: ''
      },
      rules: window.validationRules.getRules(),

      errorPass: '',
      validPass: true,
    }
  },
  methods: {
    ...mapActions([
      'loginUser',
      'setLoginModal'
    ]),
    setLoginModal() {
      this.$emit('closeModal');
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.error = '';

        window.auth.login(this, this.credentials)
      }
    },
    submitPassword() {
      this.loading = true;
      this.error = '';
      window.auth.forgottenPassword(this, this.credentials);
      setTimeout(() => {
        this.loading = false;
        this.forgotten = false;
      }, 2000);
      this.setLoginModal();
    }
  }
}
</script>
