class ValidationRules {

    static getRules() {
        return {
            required: value => !!value || 'Toto pole je povinné',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Zadejte validní email'
            },
            maxLength: v => (v.length && v.length <= 50) || 'Je povoleno maximálně 50 znaků',
            minPassword: value => value ? value.length >= 8 || 'Minimum je 8 znaků' : true,
        };
    }
}

export default ValidationRules;
