<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2 class="mb-3">Moje objednávky</h2>
        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="orders"
            :items-per-page="10"
            class="elevation-1"
            hide-default-footer
            no-data-text="Doposud nebyla nalezena žádná objednávka."
        >
          <template v-slot:item.orderDate="{ item }">
            {{ getDate(item.orderDate) }}
          </template>
          <template v-slot:item.totalWithoutVat="{ item }">
            {{ item.totalWithoutVat }} Kč
          </template>
          <template v-slot:item.totalWithVat="{ item }">
            {{ item.totalWithVat }} Kč
          </template>
          <template v-slot:item.paymentState="{ item }">
            <v-chip
              small
              :color="getPaymentStateColor(item.paymentState)"
            >
              {{ getPaymentState(item.paymentState) }}
            </v-chip>
          </template>
          <template v-slot:item.orderState="{ item }">
            <v-chip
                small
                :color="getOrderStateColor(item.orderState)"
            >
              {{ getOrderState(item.orderState) }}
            </v-chip>
          </template>
          <template v-slot:item.invoiceUrl="{ item }">
            <span v-if="item.invoiceUrl">
              <v-btn icon :href="item.invoiceUrl" target="_blank">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </span>
            <span v-else>
              -
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'Videos',
    mounted() {
      this.loadOrders();
    },
    computed: {
      ...mapGetters([
          'user'
      ])
    },
    data() {
      return {
        orders: [],
        loading: false,
        headers: [
          {
            text: 'Číslo',
            align: 'start',
            sortable: false,
            value: 'number',
          },
          { text: 'Datum objednávky', value: 'orderDate', sortable: false },
          { text: 'Cena bez DPH', value: 'totalWithoutVat', sortable: false },
          { text: 'Cena s DPH', value: 'totalWithVat', sortable: false },
          { text: 'Stav objednávky', value: 'orderState', sortable: false },
          { text: 'Platební metoda', value: 'paymentMethod', sortable: false },
          { text: 'Stav platby', value: 'paymentState', sortable: false },
          { text: 'Faktura', value: 'invoiceUrl', sortable: false },
        ],
      }
    },
    methods: {
      getDate(date) {
        const parsedDate = new Date(date);
        return parsedDate.getDate() + '. ' + (parsedDate.getMonth() + 1) + '. ' + parsedDate.getFullYear();
      },
      getPaymentState(state) {
        switch (state) {
          case 'Pending':
            default:
              return 'Zpracovává se';
              case 'Paid':
                return 'Zaplaceno';
        }
      },
      getPaymentStateColor(state) {
        switch (state) {
          case 'Pending':
          default:
            return 'info';
          case 'Paid':
            return 'success';
        }
      },
      getOrderState(state) {
        switch (state) {
          case 1:
          default:
            return 'Zpracovává se';
          case 3:
            return 'Hotovo';
        }
      },
      getOrderStateColor(state) {
        switch (state) {
          case 1:
          default:
            return 'info';
          case 3:
            return 'success';
        }
      },
      loadOrders() {
        this.loading = true;
        window.axios.get(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_ACCOUNT + '/orders', {
          headers: {
            Authorization: window.auth.getAuthHeader()
          }
        }).then(response => {
        this.onLoadOrderSuccess(response);
        }).catch(error => {console.log(error)});
      },
      onLoadOrderSuccess(response) {
        this.orders = response.data;
        this.loading = false;
      }
    }
  }
</script>
