<template>
    <v-card flat>
        <v-card-title class="pb-0">
            <h3 class="checkout-section-title">Dodací metody</h3>
        </v-card-title>
        <v-card-text>
            <v-radio-group v-model="form.shippingMethodId" v-if="shippingMethods.length" :rules="[rules.required]">
                <v-radio
                        v-for="n in shippingMethods"
                        :key="n.id"
                        :label="n.name"
                        :value="n.id"
                >
                  <template v-slot:label>
                    <v-list class="pa-0">
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="title">
                            {{ n.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </template>
                </v-radio>
            </v-radio-group>
            <div v-if="!shippingMethods.length">
                Bohužel nejsou k dispozici žádné dodací metody.
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    export default {
        name: 'DeliveryMethods',
        props: ['form'],
        data () {
            return {
                rules: window.validationRules.getRules()
            }
        },
        mounted() {
            if (!this.shippingMethods.length) {
                this.retrieveShippingMethods();
            }
        },
        computed: {
            ...mapGetters([
                'shippingMethods',
                'shippingMethodsLoaded'
            ])
        },
        methods: {
            ...mapActions([
                'retrieveShippingMethods'
            ])
        }
    }
</script>
