<template>

  <!-- Apollo watched Graphql query -->
  <ApolloQuery
      :query='gql => gql`query MyQuery($videoId: [QueryArgument]) {entry(id: $videoId) { id, title, ... on videos_videos_Entry { difficulity, vimeoPreviewId, videoId, embedCode, description, similarVideos {id}, otherVideos {id}, tags {id, title}, creator { id, title, ... on authors_authors_Entry { photo { url } photoHorizontal { url }, text } } } } }`'
      :variables="{ videoId }">
    <template slot-scope="{ result: { loading, error, data } }">
      <!-- Loading -->
      <div v-if="loading" class="loading apollo">Loading...</div>
      <!-- Error -->
      <div v-else-if="error" class="error apollo">An error occured</div>
      <div v-else-if="data">
        <section class="detail video">
          <div class="container">
            <div class="main-heading">
              <h2 v-html="data.entry.title" class="block md:inline-block mr-30 !mb-[20px]"/>
              <a v-if="false" href="#" class="button button-pink inline-block relative top-[-6px] !pt-[8px]">
                <svg style="width:24px;height:24px;" class="inline-block mr-1 relative top-[-2px] left-[-2px]"
                     viewBox="0 0 24 24">
                  <path fill="currentColor"
                        d="M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z"/>
                </svg>
                Přidat na můj list
              </a>
            </div>
            <div class="video" v-html="data.entry.embedCode.replace(data.entry.videoId, data.entry.vimeoPreviewId)"></div>
          </div>

          <div class="video-info">
            <div class="container">
              <div class="about">
          <span class="relative top-3.5 !mr-25">
              <svg v-if="data.entry.difficulity === '1'" xmlns="http://www.w3.org/2000/svg" width="104.043"
                   height="29.043" viewBox="0 0 104.043 29.043">
  <g id="Group_30" data-name="Group 30" transform="translate(-75 -745.582)">
    <g id="Group_27" data-name="Group 27" transform="translate(75 745.582)">
      <path id="dumbell"
            d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
            transform="translate(-0.004 0)" fill="#d11a6f"/>
    </g>
    <g id="Group_28" data-name="Group 28" transform="translate(112 745.582)" opacity="0.306">
      <path id="dumbell-2" data-name="dumbell"
            d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
            transform="translate(-0.004 0)" fill="#fff"/>
    </g>
    <g id="Group_29" data-name="Group 29" transform="translate(150 745.582)" opacity="0.306">
      <path id="dumbell-3" data-name="dumbell"
            d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
            transform="translate(-0.004 0)" fill="#fff"/>
    </g>
  </g>
</svg>

            <svg v-if="data.entry.difficulity == '2'" xmlns="http://www.w3.org/2000/svg" width="104.043" height="29.043"
                 viewBox="0 0 104.043 29.043">
  <g id="Group_30" data-name="Group 30" transform="translate(-75 -745.582)">
    <g id="Group_27" data-name="Group 27" transform="translate(75 745.582)">
      <path id="dumbell"
            d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
            transform="translate(-0.004 0)" fill="#d11a6f"/>
    </g>
    <g id="Group_28" data-name="Group 28" transform="translate(112 745.582)" opacity="0.306">
      <path id="dumbell-2" data-name="dumbell"
            d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
            transform="translate(-0.004 0)" fill="#fff"/>
    </g>
    <g id="Group_29" data-name="Group 29" transform="translate(150 745.582)" opacity="0.306">
      <path id="dumbell-3" data-name="dumbell"
            d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
            transform="translate(-0.004 0)" fill="#fff"/>
    </g>
    <g id="Group_46" data-name="Group 46">
      <g id="Group_27-2" data-name="Group 27" transform="translate(75 745.582)">
        <path id="dumbell-4" data-name="dumbell"
              d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
              transform="translate(-0.004 0)" fill="#d11a6f"/>
      </g>
      <g id="Group_28-2" data-name="Group 28" transform="translate(112 745.582)">
        <path id="dumbell-5" data-name="dumbell"
              d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
              transform="translate(-0.004 0)" fill="#d11a6f"/>
      </g>
      <g id="Group_29-2" data-name="Group 29" transform="translate(150 745.582)" opacity="0.306">
        <path id="dumbell-6" data-name="dumbell"
              d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
              transform="translate(-0.004 0)" fill="#fff"/>
      </g>
    </g>
  </g>
</svg>


            <svg v-if="data.entry.difficulity == '3'" xmlns="http://www.w3.org/2000/svg" width="104.043" height="29.043"
                 viewBox="0 0 104.043 29.043">
  <g id="Group_30" data-name="Group 30" transform="translate(-75 -745.582)">
    <g id="Group_27" data-name="Group 27" transform="translate(75 745.582)">
      <path id="dumbell"
            d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
            transform="translate(-0.004 0)" fill="#d11a6f"/>
    </g>
    <g id="Group_28" data-name="Group 28" transform="translate(112 745.582)" opacity="0.306">
      <path id="dumbell-2" data-name="dumbell"
            d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
            transform="translate(-0.004 0)" fill="#fff"/>
    </g>
    <g id="Group_29" data-name="Group 29" transform="translate(150 745.582)" opacity="0.306">
      <path id="dumbell-3" data-name="dumbell"
            d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
            transform="translate(-0.004 0)" fill="#fff"/>
    </g>
    <g id="Group_46" data-name="Group 46">
      <g id="Group_27-2" data-name="Group 27" transform="translate(75 745.582)">
        <path id="dumbell-4" data-name="dumbell"
              d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
              transform="translate(-0.004 0)" fill="#d11a6f"/>
      </g>
      <g id="Group_28-2" data-name="Group 28" transform="translate(112 745.582)">
        <path id="dumbell-5" data-name="dumbell"
              d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
              transform="translate(-0.004 0)" fill="#d11a6f"/>
      </g>
      <g id="Group_29-2" data-name="Group 29" transform="translate(150 745.582)">
        <path id="dumbell-6" data-name="dumbell"
              d="M14.674,22.946a2.321,2.321,0,0,1,0,3.286l-2.049,2.049a2.326,2.326,0,0,1-3.286,0L.765,19.707a2.327,2.327,0,0,1,0-3.286l2.049-2.049a2.321,2.321,0,0,1,3.286,0ZM1.111,21.94a.466.466,0,0,0-.72.077A2.3,2.3,0,0,0,.683,24.93l3.433,3.433a2.3,2.3,0,0,0,2.912.292.466.466,0,0,0,.078-.72ZM16.083,9.8a.93.93,0,0,0-1.314,0L9.8,14.765a.929.929,0,0,0,0,1.314l3.164,3.164a.93.93,0,0,0,1.314,0l4.966-4.966a.929.929,0,0,0,0-1.314ZM27.939,7.1a.466.466,0,0,0,.72-.078,2.3,2.3,0,0,0-.294-2.911L24.933.68A2.3,2.3,0,0,0,22.022.386a.466.466,0,0,0-.077.72ZM19.71.762a2.326,2.326,0,0,0-3.286,0L14.375,2.811a2.321,2.321,0,0,0,0,3.286l8.574,8.574a2.322,2.322,0,0,0,3.286,0l2.049-2.049a2.327,2.327,0,0,0,0-3.286Z"
              transform="translate(-0.004 0)" fill="#d11a6f"/>
      </g>
    </g>
  </g>
</svg>


          </span>
                <span>{{ difficulities[data.entry.difficulity] }}</span>
                <span v-if="data.entry.otherVideos.length">
                <span>|</span>
                <span>Počet lekcí: {{ data.entry.otherVideos.length + 1 }}</span>
                </span>

                <span v-if="data.entry.creator.length">|</span>
                <span v-if="data.entry.creator.length" v-html="data.entry.creator[0].title"/>

                <span>|</span>
                <span class="relative top-3.5 !mr-15 tags">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21.382" height="21.382" viewBox="0 0 21.382 21.382">
  <g id="price-tag_1_" data-name="price-tag (1)" opacity="0.8">
    <g id="Group_45" data-name="Group 45">
      <path id="Path_17" data-name="Path 17" d="M19.154,0H13.5a3.985,3.985,0,0,0-2.836,1.174L.76,11.083a2.6,2.6,0,0,0,0,3.67l5.869,5.869a2.6,2.6,0,0,0,3.67,0l9.909-9.909a3.981,3.981,0,0,0,1.174-2.835V2.227A2.229,2.229,0,0,0,19.154,0ZM16.482,7.127A2.227,2.227,0,1,1,18.709,4.9,2.229,2.229,0,0,1,16.482,7.127Z" fill="#fff"/>
    </g>
  </g>
</svg>
                </span>
                <span>
                  <em class="not-italic" :key="key" v-for="(tag, key) in data.entry.tags"><i class="not-italic" v-if="key <= 2">{{ tag.title }}<i v-if="key <= 1 && key < data.entry.tags.length">, &nbsp;&nbsp;</i></i></em>
                </span>

              </div>
              <p v-html="data.entry.description">
              </p>
            </div>
          </div>

          <div class="author">
            <div class="container flex flex-wrap lg:flex-nowrap">
              <div class="flex-initial w-full lg:w-1/2 mb-40" v-if="data.entry.otherVideos">
                <h3 class="px-20">Další epizody</h3>

                <Episodeslist :ids="similarIds(data.entry.otherVideos)" variant="light"/>

              </div>
              <div v-if="data.entry.creator.length"
                   class="flex-initial w-full flex flex-wrap md:flex-nowrap px-10 mb-40" :class="{
                'lg:w-1/2': data.entry.otherVideos.length
              }">
                <div class="flex-initial w-full md:w-1/2 px-10">
                  <h3>
                    o autorovi
                  </h3>
                  <h2 v-html="data.entry.creator[0].title"/>
                  <div class="text-white text" v-html="data.entry.creator[0].text"></div>
                </div>
                <div class="flex-initial w-full md:w-1/2 px-10 pt-30 md:pt-0 text-left md:text-right" v-if="data.entry.creator[0].id !== '453'">
                  <!--<a href="#" class="button button-pink">Více od autorky</a>-->
                  <img v-if="!data.entry.otherVideos.length" class="w-full rounded-8"
                       :src="data.entry.creator[0].photo[0].url" alt="">
                  <img v-if="data.entry.otherVideos.length" class="w-full rounded-8"
                       :src="data.entry.creator[0].photoHorizontal[0].url" alt="">
                </div>
                <div class="flex-initial w-full md:w-1/2 px-10 pt-30 md:pt-0 text-left md:text-right" v-if="data.entry.creator[0].id === '453'">
                  <video class="rounded" autoplay muted loop>
                    <source src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>

          <div class="more">
            <SimilarVideos :ids="similarIds(data.entry.similarVideos)" heading="Podobné programy" variant="light mb-0"/>
          </div>
        </section>
      </div>
    </template>
  </ApolloQuery>
</template>

<style scoped>

.main-heading {
  padding-top: 60px;
  padding-bottom: 20px;
  @apply w-full;
}

.main-heading * {
  @apply text-white;
}

.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.button {
  padding: 6px 15px;
  margin-bottom: 20px;
  @apply rounded no-underline text-base font-500 font-fira inline-block;
}

.button-pink {
  @apply text-white bg-pink;
}

.video-info {
  @apply pt-10 pb-50;
}

.detail.video {
  background: #080212;
  min-height: 100vh;
}

.more {
  background-image: linear-gradient(to bottom, rgba(209, 26, 111, 0.22), rgba(0, 0, 0, 0) 76%);
  @apply pt-50 pb-30;
}

.about {
  @apply w-full mb-10;
}

.about span {
  @apply inline-block mr-15 font-500 text-18 text-white mb-10;
}

div.text, p {
  @apply font-fira text-15 text-white leading-relaxed mb-0;
}

h2 {
  @apply text-pink uppercase font-500 text-32 font-fira leading-tight mb-10;
}

h3 {
  @apply text-white uppercase font-500 text-20 font-fira leading-tight mb-10;
}


.author .container {
  @apply px-0 ;
}

.author .half {
  @apply w-1/2 py-10 px-20;
}

</style>

<script>

import {mapGetters, mapActions} from 'vuex'
import SimilarVideos from "@/components/Elixir/SimilarVideos";
import Episodeslist from "@/components/Elixir/EpisodesList";

export default {
  name: 'DetailPreview',
  data() {
    return {
      videoId: null,
      difficulities: {
        1: 'Začátečníci a mírně pokročilí',
        2: 'Středně pokročilí',
        3: 'Velmi pokročilí'
      }
    }
  },
  metaInfo() {
    return {
      title: 'Elixír Active',
    }
  },
  watch: {
    loadingCategories: function (newValue) {
      if (!newValue) {
        this.changeCategory();
      }
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const videoId = urlParams.get('video');
    this.videoId = videoId;
    //this.changeCategory();
  },
  components: {Episodeslist, SimilarVideos},
  computed: {
    ...mapGetters([
      'allProducts',
      'currentCategory',
      'loadingCategories'
    ])
  },
  methods: {

    similarIds(ids) {
      let _ids = [];
      for (let i in ids) {
        _ids.push(ids[i].id);
      }
      return _ids;
    },


    ...mapActions([
      'changeCategory'
    ])
  }
}
</script>
