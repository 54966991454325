<template>
  <div class="calendar">

    <div class="month">
      <div class="inner">

        <div class="w-full flex justify-between p-[9px] items-start flex-wrap">
          <div class="w-full md:w-auto flex-initial">
            <h2>Kalendář lekcí</h2>
            <div class="w-full flex items-center">
              <div class="flex-initial mr-40 mb-25 flex items-center">
                <span class="flex-initial inline-block w-[30px] h-[30px] border-2 border-[#d11a6f] rounded-8 mr-[8px]">&nbsp;</span>
                <p class="flex-initial inline-block text-18 text-[#a5a5a5] mb-0">Vybrané datum</p>
              </div>
              <div class="hidden md:block flex-initial mb-25 flex items-center">
                <span class="flex-initial inline-block w-[30px] h-[30px] bg-[#d11a6f] rounded-8 mr-[8px]">&nbsp;</span>
                <p class="flex-initial inline-block text-18 text-[#a5a5a5] mb-0">Účastníte se</p>
              </div>
            </div>
          </div>
          <div class="justify-center pt-[12px] pb-30 md:pt-0 md:pb-0 w-full md:w-auto flex-initial flex items-center">
            <a v-show="false" href="#" class="flex-initial inline-block">
              <svg id="btnPrev" xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 33 34">
                <g id="ic_chevron">
                  <path id="ic_chevron_Background_Mask_" data-name="ic_chevron (Background/Mask)" d="M0,0H33V-34H0Z" transform="translate(33) rotate(180)" fill="none"/>
                  <path id="Vector" d="M8.25,17l0,0L0,8.5,8.25,0V17Z" transform="translate(12.375 8.5)" fill="rgba(22,22,22,0.19)"/>
                </g>
              </svg>
            </a>
            <p class="flex-initial inline-block text-20 font-500 text-[#161616] my-0 mx-25">{{ currentMonth }}</p>
            <a v-show="false" href="#" class="flex-initial inline-block">
              <svg id="btnNext" xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 33 34">
                <g id="ic_chevron">
                  <path id="ic_chevron_Background_Mask_" data-name="ic_chevron (Background/Mask)" d="M0,0H33V34H0Z" fill="none"/>
                  <path id="Vector" d="M0,17V0L8.25,8.5,0,17Z" transform="translate(12.375 8.5)" fill="rgba(22,22,22,0.19)"/>
                </g>
              </svg>
            </a>
          </div>
        </div>

        <div class="w-full">
          <table>
            <tr class="hidden md:table-row">
              <th>Pondělí</th>
              <th>Úterý</th>
              <th>Středa</th>
              <th>Čtvrtek</th>
              <th>Pátek</th>
              <th>Sobota</th>
              <th>Neděle</th>
            </tr>
            <tr class="table-row md:hidden">
              <th>PO</th>
              <th>ÚT</th>
              <th>ST</th>
              <th>ČT</th>
              <th>PÁ</th>
              <th>SO</th>
              <th>NE</th>
            </tr>
            <tr v-for="week in weeks" :key="week">
              <td v-for="day in week" :key="day">
                <day :selected-date="new Date(date)" :date="new Date(day)" :events="getEvents(day)" />
                <!--<day-in-month :day="day" :month="month" :year="year" :is-in-future="isInFuture(day)" />-->
              </td>
            </tr><!--
            <tr>
              <td>
                <div class="day disabled">
                  <span class="number">29</span>
                </div>
              </td>
              <td>
                <a href="#" class="day disabled">
                  <span class="number">30</span>
                </a>
              </td>
              <td>
                  <day :selected-date="new Date(date)" :date="new Date('2021-12-01')" :events="getEvents('2021-12-01')" />
              </td>
              <td>
                  <day :selected-date="new Date(date)" :date="new Date('2021-12-02')" :events="getEvents('2021-12-02')" />
              </td>
              <td>
                  <day :selected-date="new Date(date)" :date="new Date('2021-12-03')" :events="getEvents('2021-12-03')" />
              </td>
              <td>
                  <day :selected-date="new Date(date)" :date="new Date('2021-12-04')" :events="getEvents('2021-12-04')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-05')" :events="getEvents('2021-12-05')" />
              </td>
            </tr>
            <tr>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-06')" :events="getEvents('2021-12-06')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-07')" :events="getEvents('2021-12-07')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-08')" :events="getEvents('2021-12-08')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-09')" :events="getEvents('2021-12-09')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-10')" :events="getEvents('2021-12-10')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-11')" :events="getEvents('2021-12-11')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-12')" :events="getEvents('2021-12-12')" />
              </td>
            </tr>
            <tr>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-13')" :events="getEvents('2021-12-13')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-14')" :events="getEvents('2021-12-14')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-15')" :events="getEvents('2021-12-15')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-16')" :events="getEvents('2021-12-16')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-17')" :events="getEvents('2021-12-17')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-18')" :events="getEvents('2021-12-18')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-19')" :events="getEvents('2021-12-19')" />
              </td>
            </tr>
            <tr>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-20')" :events="getEvents('2021-12-20')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-21')" :events="getEvents('2021-12-21')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-22')" :events="getEvents('2021-12-22')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-23')" :events="getEvents('2021-12-23')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-24')" :events="getEvents('2021-12-24')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-25')" :events="getEvents('2021-12-25')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-26')" :events="getEvents('2021-12-26')" />
              </td>
            </tr>
            <tr>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-27')" :events="getEvents('2021-12-27')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-28')" :events="getEvents('2021-12-28')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-29')" :events="getEvents('2021-12-29')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-30')" :events="getEvents('2021-12-30')" />
              </td>
              <td>
                <day :selected-date="new Date(date)" :date="new Date('2021-12-31')" :events="getEvents('2021-12-31')" />
              </td>
              <td>
                <div class="day disabled">
                  <span class="number">1</span>
                </div>
              </td>
              <td>
                <div class="day disabled">
                  <span class="number">2</span>
                </div>
              </td>
            </tr>-->
          </table>
        </div>

      </div>
    </div>

    <div class="list">
      <div class="inner">

        <h3>{{ (new Date(date)).toLocaleDateString('cs-CZ') }}</h3>

        <div v-if="eventsOnSelectedDay.length">
          <event v-for="e in eventsOnSelectedDay" :key="e.id" :item="e" />
        </div>
        <div v-else>
          <p class="no-event">V tento den se nekoná žádná událost.</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Day from "../components/Calendar/Day";
import Event from "../components/Calendar/Event";
//import calendar from 'calendar-month-array';
var calendar = require('calendar-month-array');
import gql from "graphql-tag";

export default {
  data() {
    return {
      date: null,
    }
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    entries: gql`query Calendar {
  entries(type: "calendar") {
    id,
    title,
    ... on calendar_calendar_Entry {
      date
      zoomUrl
    }
  }
}`,
  },
  components: {
    Day, Event
  },
  mounted() {
    if(!this.date) {
      this.date = new Date();
    }
  },
  methods: {
    selectDate(date) {
      this.date = new Date(date);
    },
    getEvents(date) {

      if(!this.entries) return [];

      return this.entries.filter((item) => {
        return (new Date(item.date)).toDateString() === (new Date(date)).toDateString();
      });
    },
  },
  computed: {

    currentMonth() {
      const today = new Date();
      let currentMonth = today.toLocaleString('default', { month: 'long' });
      currentMonth = currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
      const year = today.getFullYear();
      return `${currentMonth} ${year}`;
    },

    weeks() {
      return calendar(new Date(), {
        weekStartDay: 1
      });
    },

    eventsOnSelectedDay() {
      return this.getEvents(this.date);
    }
  }
}
</script>

<style scoped lang="scss">
.calendar {
  @apply flex flex-wrap items-stretch py-50 w-full max-w-[1540px] px-20 mx-auto;
}
.month {
  @apply flex-initial w-full mb-40 xl:mb-0 xl:w-3/4;
}
.list {
  @apply flex-initial w-full xl:w-1/4 xl:pl-40;
}
.inner {
  @apply xl:rounded-[15px] xl:bg-white w-full xl:p-20 xl:h-full;
}
@media (min-width: 1280px) {
  .inner {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.04);
  }
}
.list .inner {
  @apply p-0;
}
h2 {
  @apply font-bold text-[34px] mb-20 normal-case text-[#202020];
}
h3 {
  @apply font-bold text-[24px] mb-10 normal-case text-[#202020] xl:pt-[29px] xl:px-[29px];
}
table {
  @apply w-full;
}
th {
  @apply text-center md:text-left font-500 text-20 text-[#a5a5a5] p-5 lg:px-[9px] lg:py-0;
}
th, td {
  width: calc(100% / 7);
}
td {
  @apply py-[9px] px-5 lg:p-[9px];
}

// Disabled day


// Occupied
.day.occupied {
  @apply md:border-[#d11a6f] md:bg-[#d11a6f];
}
.day.occupied .number {
  @apply md:text-white;
}
.no-event {
  @apply relative w-full border-b border-[#ececec] block pl-25 pr-0 xl:px-30 py-[23px] flex no-underline;
}

</style>

