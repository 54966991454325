<template>
    <v-card>
        <v-toolbar flat height="30px">
            <v-spacer/>
            <v-btn icon small @click="setLoginModal()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <v-card-text class="pt-0">
            <v-form v-model="valid" ref="form" lazy-validation @keyup.native.enter="submit">
                <v-alert
                        v-if="error"
                        type="error"
                        outlined
                >
                    {{ error }}
                </v-alert>
                <v-text-field
                        name="password"
                        label="Heslo" id="password"
                        type="password"
                        v-model="credentials.password"
                        :rules="[rules.required]"
                ></v-text-field>
                <div class="pt-2">
                    <v-btn color="primary" :loading="loading" :disabled="loading" block class="ml-0 font-weight-medium" @click="submit">Změnit heslo</v-btn>
                </div>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>

import {mapActions} from 'vuex'
    export default {
        name: 'PasswordModal',
        data() {
            return {
                error: '',
                addProject: null,
                valid: true,
                loading: false,
                credentials: {
                    username: '',
                    password: ''
                },
                rules: window.validationRules.getRules(),
            }
        },
        methods: {
          ...mapActions([
              'loginUser',
              'setLoginModal'
          ]),

          getUserId() {
            return localStorage.getItem('id');
          },

          getAuthHeader() {
            return 'Bearer ' + localStorage.getItem('token');
          },

          setLoginModal() {
            this.$emit('closeModal');
          },
            submit () {
                if (this.$refs.form.validate()) {
                  window.axios.post('https://api.elixironline.cz/api/account/password?id=' + this.getUserId(),
                      {
                        'password': this.credentials.password
                      }, {headers: {'Authorization': this.getAuthHeader()}
                      });
                }
            }
        }
    }
</script>
