<template>
    <v-card flat tile color="grey lighten-4">
        <v-card-title>
            <h3 class="checkout-section-title mx-auto mb-2">Vaše objednávka</h3>
        </v-card-title>
        <v-card-text class="pa-md-4 pa-0">
            <v-card flat tile>
                <v-card-text class="pa-md-4 pa-0">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr class="header-table">
                                <th class="text-left">Produkt</th>
                                <th class="text-right">Mezisoučet</th>
                            </tr>
                            </thead>
                            <tbody v-if="cartProducts.length && cartProducts[0] !== null">
                            <tr v-for="item in cartProducts" :key="item.isGiftCard? item.giftCardId : item.productId" class="grey--text">
                                <td>
                                    <p class="mb-0 black--text">{{ item.name }} x {{ item.qty }}</p>
                                    <div v-if="item.giftCard">
                                        <p class="mb-1 mt-2">Jméno osoby: <span class="black--text">{{item.giftCard.name}}</span></p>
                                        <p class="mb-0" v-if="item.giftCard.text">Věnování: <span class="black--text">{{item.giftCard.text}}</span></p>
                                    </div>
                                </td>
                                <td class="text-right">{{ formatThousands(getPrice(item.amountWithVat, item.qty)) }} Kč</td>
                            </tr>
                            <tr>
                                <th class="header-item-title">
                                    Mezisoučet
                                </th>
                                <td class="text-right primary--text">
                                    {{ formatThousands(total) }} Kč
                                </td>
                            </tr>
                            <tr v-if="user && user.discount">
                                <th class="header-item-title">
                                    Sleva
                                </th>
                                <td class="text-right primary--text">
                                    {{ discount }} Kč
                                </td>
                            </tr>
                            <tr v-if="selectedShippingMethod">
                                <th class="header-item-title">
                                    Doprava
                                </th>
                                <td class="text-right">
                                    {{ selectedShippingMethod.name }}
                                </td>
                            </tr>
                            <tr v-if="selectedPaymentMethod">
                                <th class="header-item-title">
                                    Platba
                                </th>
                                <td class="text-right">
                                    {{ selectedPaymentMethod.name }}
                                </td>
                            </tr>
                            <tr class="order-total">
                                <th>Cena celkem</th>
                                <td class="text-right">
                                    <span v-if="user && discount" class="primary--text">{{ formatThousands(totalDiscount) }} Kč</span>
                                    <span v-else class="primary--text">{{ formatThousands(total) }} Kč</span>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>
    import {mapGetters} from 'vuex'
    import {formatThousands} from '../../Helper'

    export default {
        name: 'Summary',
        mixins: [formatThousands],
        props: ['form'],
        computed: {
            ...mapGetters([
                'cartProducts',
                'user',
                'shippingMethods',
                'paymentMethods'
            ]),
            total () {
                if (this.cartProducts[0] === null) return null;
                return this.cartProducts.reduce((total, p) => {
                    return total + p.amountWithVat * p.qty
                }, 0)
            },
            totalDiscount() {
                if (this.cartProducts[0] === null) return null;
                let total = this.cartProducts.reduce((total, p) => {
                    return total + p.amountWithVat * p.qty
                }, 0);

                if (this.discount) {
                    return parseInt(total) - this.discount;
                }

                return total;
            },
            discount() {
                if (this.user && this.user.discount) {
                    return Math.round(this.total *  this.user.discount);
                }

                return false;
            },
            selectedShippingMethod() {
                return this.shippingMethods.find(p => p.id === this.form.shippingMethodId);
            },
            selectedPaymentMethod() {
                return this.paymentMethods.find(p => p.id === this.form.paymentMethodId);
            }
        },
        methods: {
            getPrice(price, quantity) {
                return quantity * price;
            }
        }
    }
</script>

<style lang="scss">
    .header-table {
        th {
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 600;
        }
    }
    th.header-item-title {
        font-size: 14px;
    }
    tr.order-total {
        th {
            font-size: 18px;
            font-weight: 400;
        }
        td {
            font-size: 22px;
            font-weight: 600 !important;
        }
    }
</style>
