<template>
    <div>
      <div
          :style="'background: url(' + require('../assets/products/background-1.jpg') + ');     height: 250px;   background-position: center;\n'+
'    background-repeat: no-repeat;\n'+
'    background-size: cover;overflow: hidden'"
      >
      </div>
      <product-detail />
    </div>
</template>

<script>
    import ProductDetail from '../components/Product/ProductDetail'
    import {getProductImage} from '@/Helper'
    import {mapActions, mapGetters} from 'vuex'

    export default {
        name: 'ProductPage',
        metaInfo () {
            return {
                title: this.currentProduct ? this.currentProduct.name : '',
            }
        },
        mixins: [getProductImage],
        components: {
            ProductDetail
        },
        computed: {
            ...mapGetters([
                'allProducts',
                'currentProduct',
                'loadingCategories'
            ]),
            imageData() {
                if (this.currentProduct) {
                    return this.currentProduct.imageData;
                }

                return null;
            }
        },
        mounted() {
            let productAlias = this.$router.currentRoute.params;
            if (typeof productAlias.alias !== 'undefined') {
                this.changeProduct();
            }
        },
        methods: {
            ...mapActions([
                'changeProduct',
            ]),
        }
    }
</script>

