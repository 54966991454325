import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'

import Category from '../pages/Category'
import Product from '../pages/Product'
import CheckCustomer from '../pages/CheckCustomer'
import Checkout from '../pages/Checkout'
import CheckoutSuccess from "../pages/CheckoutSuccess";
import Cart from '../pages/Cart'
import Calendar from '../pages/Calendar'
import PaymentFeedback from '../pages/PaymentFeedback'
import ScanProduct from '../pages/ScanProduct'
import CookiePolicy from '../pages/CookiePolicy'
import GiftProduct from '../pages/GiftProduct'
import Homepage from "@/pages/Homepage";
import LandingPage from "@/pages/LandingPage";
import Videos from "@/pages/Account/Videos";
import Videos2 from "@/pages/Videos";
import Preview from "@/pages/Preview";
import Search from "@/pages/Search";
import Manager from "@/pages/Manager";
import Detail from "@/pages/Detail";
import CreateAccount from "@/pages/CreateAccount";
import CreateSubscription from "@/pages/CreateSubscription";
import PaymentStatus from "@/pages/PaymentStatus";
import Challenge from "@/pages/Challenge";
import Membership from "@/pages/Membership";

Vue.use(VueMeta);
Vue.use(Router);

import {requireAuth} from "@/auth";
import Orders from "@/pages/Account/Orders";
import DetailPreview from "../pages/DetailPreview";

export default new Router({
    mode: 'history',
    routes: [
        { path: '/', component: Homepage, name: 'Homepage' },
        { path: '/landing-page', component: LandingPage, name: 'LandingPage' },
        { path: '/category/:alias', component: Category, name: 'Category' },
        { path: '/videos', component: Videos2, name: 'Videos', beforeEnter: requireAuth },
        { path: '/preview', component: Preview, name: 'Preview' },
        { path: '/vytvoreni-uctu/:period', component: CreateAccount, name: 'CreateAccount' },
        { path: '/subscription/:period', component: CreateSubscription, name: 'CreateAccount' },
        { path: '/platba/:status', component: PaymentStatus, name: 'PaymentStatus' },
        { path: '/vyzvy', component: Challenge, name: 'Challenge', beforeEnter: requireAuth },
        { path: '/search', component: Search, name: 'Search', beforeEnter: requireAuth },
        { path: '/manager', component: Manager, name: 'Manager', beforeEnter: requireAuth },
        { path: '/detail', component: Detail, name: 'VideosDetail', beforeEnter: requireAuth },
        { path: '/detail-preview', component: DetailPreview, name: 'VideosDetailPreview' },
        { path: '/my-account/videos', component: Videos, name: 'Videos', beforeEnter: requireAuth },
        { path: '/my-account/orders', component: Orders, name: 'Orders', beforeEnter: requireAuth },
        { path: '/product/:alias', component: Product, name: 'Detail' },
        { path: '/gift-product/:alias', component: GiftProduct },
        { path: '/scan/product', component: ScanProduct},
        { path: '/check-customer', component: CheckCustomer, name: 'CheckingCustomer' },
        { path: '/cart', component: Cart, name: 'Cart' },
        { path: '/clenstvi', component: Membership, name: 'Membership', beforeEnter: requireAuth },
        { path: '/calendar', component: Calendar, name: 'Calendar', beforeEnter: requireAuth },
        { path: '/checkout', component: Checkout, name: 'Checkout' },
        { path: '/checkout/success', component: CheckoutSuccess, name: 'CheckoutSuccess' },
        { path: '/payment/feedback/:eshopId/', component: PaymentFeedback},
        { path: '/privacy', component: CookiePolicy}
    ],
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})
