<template>
  <v-col cols="12" :class="[{'mb-7': marginBottom}, textAlign]">
    <h3 class="display-2 font-weight-bold" :class="textColor">{{ title }}</h3>
    <div class="divider-primary" :class="[{'my-10': marginBottom, 'mt-10': !marginBottom}, dividerColor, dividerAlign]"></div>
    <v-col cols="12" sm="8" class="mx-auto" v-if="subtitle">
      <p>{{ subtitle }}</p>
    </v-col>
  </v-col>
</template>

<script>
  export default {
    name: 'SectionTitle',
    props: {
      title: {
          type: String
      },
      subtitle: {
        type: String
      },
      textColor: {
        default: 'black--text',
        type: String
      },
      dividerColor: {
        default: 'primary',
        type: String
      },
      marginBottom: {
        default: true,
        type: Boolean
      },
      align: {
        default: 'text-center',
        type: String
      }
    },
    computed: {
      textAlign() {
        if (this.$vuetify.breakpoint.xsOnly) {
          return 'text-center';
        }
        return this.align;
      },
      dividerAlign() {
        if (this.$vuetify.breakpoint.xsOnly) {
          return 'mx-auto';
        }
        return this.align === 'text-center' ? 'mx-auto' : '';
      }
    }
  }
</script>

<style lang="scss">
  .divider-primary {
    width: 40px;
    height: 2px;
  }
</style>
