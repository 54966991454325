import router from '../router/index.js'
import store from '../store/index'

export default {

    // User object will let us check authentication status
    user: {
        authenticated: false
    },

    // Send a request to the login URL and save the returned JWT
    login(context, credentials) {
        //window.axios.post(process.env.VUE_APP_ROOT_API + '/api/account/login', {
        window.axios.post('https://api.elixironline.cz/api/account/login', {
            userName: credentials.username,
            password: credentials.password,
            clientId: process.env.VUE_APP_API_CLIENT_ID
        }).then(response => {
            this.onLoginSuccess(context, response);
            if(credentials.username.includes('admin')) {
                router.push('/manager');
            } else {
                router.push('/videos');
            }

        }).catch(error => {this.onLoginError(context, error)});
    },

    forgottenPassword(context, credentials) {
        //window.axios.post(process.env.VUE_APP_ROOT_API + '/api/account/login', {
        window.axios.post('https://api.elixironline.cz/api/account/forgotten-password', {
            userName: credentials.username
        }).then((response) => {
            console.log(response);
            alert('Na zadaný email jsme vám odeslali nové heslo.');
        }).catch(error => {this.onLoginError(context, error)});
    },

    onLoginSuccess(context, response) {
        localStorage.setItem('id', response.data.user.id);
        localStorage.setItem('company_id', response.data.user.company_id);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('expiresIn', response.data.expiresIn);
        this.setUserState(context);
        store.dispatch('setLoginModal');
    },
    onLoginError(context, error) {
        context.loading = false;
        context.error = error.data.errors[0].msg;
    },

    setUserState(context) {
        //window.axios.get(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_ACCOUNT + '/profile',
        window.axios.get('https://api.elixironline.cz/api/account/profile?id=' + this.getUserId(),
            {headers: {'Authorization': this.getAuthHeader()}
            }).then(response => this.getUserDataSuccess(context, response));
    },

    getUserDataSuccess(context, response) {
        let userData = response.data;

        store.dispatch('loginUser', userData);
        context.loading = false;
    },

    // To log out, we just need to remove the token
    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('expiresIn');
        this.user.authenticated = false;

        if (router.currentRoute.name !== 'Homepage') {
            router.push('/');
        }
    },

    checkAuth() {
        this.user.authenticated = !!isLoggedIn();

        if (this.user.authenticated) {
            this.setUserState(this);
        }

        return this.user.authenticated;
    },

    // The object to be passed as a header for authenticated requests
    getAuthHeader() {
        return 'Bearer ' + localStorage.getItem('token');
    },

    getUserId() {
        return localStorage.getItem('id');
    },

    getCompanyId() {
        return localStorage.getItem('company_id');
    }
}

export function requireAuth(to, from, next) {
    if (!isLoggedIn()) {
        store.dispatch('logoutUser');
        localStorage.removeItem('token');
        localStorage.removeItem('expiresIn');
        next({
            path: '/',
            query: { redirect: to.fullPath }
        });
    } else {
        next();
    }
}

export function getIdToken() {
    return localStorage.getItem('token');
}

export function isLoggedIn() {
    const idToken = getIdToken();

    return !!idToken && !isTokenExpired();
}

function isTokenExpired() {
    const expiresIn = localStorage.getItem('expiresIn');
    return expiresIn < Math.floor(new Date().getTime() / 1000);
}
