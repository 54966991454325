<template>
  <VueSlickCarousel v-bind="settings" style="margin-top: 5px;">
    <!--    POČET SLIDŮ MUSÍ BÝT DĚLITELNÝ TŘEMI   -->
    <div class="slide">
      <router-link to="/detail?video=740">
      <div class="inner">
        <video playsinline autoplay muted loop class="object-cover w-full h-full">
          <source :src="require('../../assets/videos/featured/1.mp4')" type="video/mp4" />
        </video>
        <img :src="require('../../assets/videos/featured/1.jpg')" alt="">
        <div class="shadow">&nbsp;</div>
        <div class="text">
          <h2>HIIT pro pokročilé</h2>
          <p>
            Anna Karmazínová
          </p>
        </div>
      </div>
      </router-link>
    </div>
    <div class="slide">
      <router-link to="/detail?video=65">
      <div class="inner">
        <video playsinline autoplay muted loop class="object-cover w-full h-full">
          <source :src="require('../../assets/videos/featured/2.mp4')" type="video/mp4" />
        </video>
        <img :src="require('../../assets/videos/featured/2.jpg')" alt="">
        <div class="shadow">&nbsp;</div>
        <div class="text">
          <h2>Funkční trénink pro středně pokročilé</h2>
          <p>
            Mikuláš Pýcha
          </p>
        </div>
      </div>
      </router-link>
    </div>
    <div class="slide">
      <router-link to="/detail?video=81">
      <div class="inner">
        <video playsinline autoplay muted loop class="object-cover w-full h-full">
          <source :src="require('../../assets/videos/featured/3.mp4')" type="video/mp4" />
        </video>
        <img :src="require('../../assets/videos/featured/3.jpg')" alt="">
        <div class="shadow">&nbsp;</div>
        <div class="text">
          <h2>Powerjóga pro začátečníky</h2>
          <p>
            Markéta Léblová
          </p>
        </div>
      </div>
      </router-link>
    </div>
    <div class="slide">
      <router-link to="/detail?video=939">
      <div class="inner">
        <video playsinline autoplay muted loop class="object-cover w-full h-full">
          <source :src="require('../../assets/videos/featured/4.mp4')" type="video/mp4" />
        </video>
        <img :src="require('../../assets/videos/featured/4.jpg')" alt="">
        <div class="shadow">&nbsp;</div>
        <div class="text">
          <h2>Začínáme s Pilates - Páteř a Core 4</h2>
          <p>
            Míša Malá
          </p>
        </div>
      </div>
      </router-link>
    </div>
    <div class="slide">
      <router-link to="/detail?video=1054">
      <div class="inner">
        <video playsinline autoplay muted loop class="object-cover w-full h-full">
          <source :src="require('../../assets/videos/featured/5.mp4')" type="video/mp4" />
        </video>
        <img :src="require('../../assets/videos/featured/5.jpg')" alt="">
        <div class="shadow">&nbsp;</div>
        <div class="text">
          <h2>Zumba pro všechny 3</h2>
          <p>
            Adéla Ježková
          </p>
        </div>
      </div>
      </router-link>
    </div>
    <div class="slide">
      <router-link to="/detail?video=1467">
      <div class="inner">
        <video playsinline autoplay muted loop class="object-cover w-full h-full">
          <source :src="require('../../assets/videos/featured/6.mp4')" type="video/mp4" />
        </video>
        <img :src="require('../../assets/videos/featured/6.jpg')" alt="">
        <div class="shadow">&nbsp;</div>
        <div class="text">
          <h2>Partnerské cvičení</h2>
          <p>
            Evka a Kája
          </p>
        </div>
      </div>
      </router-link>
    </div>
  </VueSlickCarousel>
</template>

<style scoped>

.slide {
  @apply px-10 py-30;
}
.inner {
  height: 20vw;
  min-height: 385px;
  @apply relative rounded-8 shadow overflow-hidden w-full;
}
.shadow {
  z-index: 50;
  background-image: linear-gradient(to bottom, rgba(21, 20, 25, 0), rgba(21, 20, 25, 0.89));

  @apply absolute bottom-0 left-0 w-full h-full;

}
.text {
  z-index: 100;
  max-width: 500px;
  @apply absolute bottom-0 left-0 p-30 w-full;
}

img {
  @apply block w-full object-cover h-full;
}

video {
  @apply hidden;
}

.slick-current video {
  @apply block;
}

.slick-current img {
  @apply hidden;
}

h2 {
  @apply text-white uppercase font-500 text-30 font-fira leading-tight mb-10;
}

p {
  @apply text-white text-15 mb-0;
}

</style>

<style>

.slick-slider {
  @apply relative mb-40;
}
.slick-arrow {
  z-index: 500;
  right: 30px;
  top: 65px;
  @apply absolute bg-transparent border-none left-auto m-0;
}
.slick-arrow:before {
  opacity: 1;
  @apply font-fira font-bold;
  font-size: 30px;
}
.slick-prev:before {
  content: url("../../assets/lt-white.svg");
}
.slick-next:before {
  content: url("../../assets/gt-white.svg");
}
.slick-prev {
  right: 65px;
}


</style>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

    export default {
        name: 'Videointro',
        components: {
          VueSlickCarousel
        },
      data() {
        return {
          settings: {
            "dots": false,
            "arrows": true,
            "infinite": true,
            "speed": 500,
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "centerMode": true,
            "centerPadding": "20%",
            "responsive": [
              {
                "breakpoint": 1200,
                "settings": {
                  "centerPadding": "10%",
                }
              },
              {
                "breakpoint": 800,
                "settings": {
                  "centerPadding": "5%",
                }
              }

            ]
          },

        }
      }

    };
</script>
