import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store/index.js'
import router from './router'
import axios from 'axios'
import ValidationRules from './utilities/ValidationRules'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import auth from './auth/index.js'
import './assets/tailwind.css'
import { createProvider } from './vue-apollo'
Vue.config.productionTip = false;

window.axios = axios;
window.validationRules = ValidationRules;
window.auth = auth;

axios.interceptors.response.use(function (response) {
  // Do something with response data
  if (response.data.errors && response.data.errors.length) {
    return Promise.reject(response.data);
  }
  return response;
}, function (error) {
  // Do something with response error
  return Promise.reject(error.response);
});

auth.checkAuth();

new Vue({
  vuetify,
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app');
