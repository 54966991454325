<template>
  <v-container grid-list-lg fluid class="pa-0">

    <v-carousel
        v-if="isMobile"
        height="100vw"
        hide-delimiter-background
        show-arrows-on-hover
        cycle
        interval="15000"
        :show-arrows="$vuetify.breakpoint.mdAndUp"
    >
      <v-carousel-item
          v-for="(slide, i) in slidesMobile"
          :key="i"
      >
        <v-sheet
            color="rgba(0, 0, 0, 0.35)"
            height="100%"
            style="z-index: 100; position:relative;"
        >
          <v-row
              class="fill-height"
              :align="slide.headingTop ? 'top' : 'center'"
              justify="center"
          >
            <video class="rounded object-cover w-100 h-full w-full absolute" :poster="require('../assets/videos/header/sq_'+ slide.poster)" playsinline autoplay muted loop>
              <source :src="require('../assets/videos/header/sq_'+ slide.video)" type="video/mp4"/>
            </video>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <div v-if="isMobile" class="text-center cover z-100">
      <v-btn rounded depressed color="primary" :max-width="300" href="#plans">Přidat se</v-btn>
    </div>

    <v-carousel
        v-if="isDesktop"
        height="100vh"
        hide-delimiter-background
        show-arrows-on-hover
        cycle
        interval="15000"
        :show-arrows="$vuetify.breakpoint.mdAndUp"
    >
      <v-carousel-item
          v-for="(slide, i) in slides"
          :key="i"
      >
        <v-sheet
            color="rgba(0, 0, 0, 0.35)"
            height="100%"
            style="z-index: 100; position:relative;"
        >
          <v-row
              class="fill-height"
              :align="slide.headingTop ? 'top' : 'center'"
              justify="center"
          >
            <video class="rounded object-cover w-100 h-full w-full absolute" :poster="require('../assets/videos/header/'+ slide.poster)" autoplay muted loop>
              <source :src="require('../assets/videos/header/'+ slide.video)" type="video/mp4"/>
            </video>
            <div class="text-center cover z-100" style="z-index: 1000; position:relative;" :class="{
              'mt-10': slide.headingTop
            }">
              <div class="text-uppercase text--white font-weight-bold font-italic mb-3"
                   :class="{'display-4': $vuetify.breakpoint.mdAndUp, 'display-1': $vuetify.breakpoint.smAndDown}" v-html="slide.title">
              </div>
              <div :class="{'headline': $vuetify.breakpoint.mdAndUp, 'subtitle-1': $vuetify.breakpoint.smAndDown}">
                <v-col cols="12" sm="6" class="mx-auto text-black">
                  {{ slide.subtitle }}
                </v-col>
              </div>
              <v-btn rounded depressed color="primary" :max-width="300" href="#plans">Přidat se</v-btn>
            </div>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
    <v-container grid-list-lg class="my-12">
      <v-row :justify="`left`" :align="`center`" class="my-12">
        <div class="col w-full xl:w-[32%] mb-0 text-left">
          <h3 class="display-2 font-weight-bold black--text">Vítejte ve světě <br>Elixír online!</h3>
          <div class="divider-primary my-8 primary"></div>

          <ul class="list-dots">
            <li>+400 natočených videí</li>
            <li>kalendář online živých lekcí</li>
            <li>atraktivní výzvy a tréninkové plány</li>
            <li>více než 20 fitness lektorů</li>
            <li>více než 20 typů cvičení</li>
            <li>návody na cvičení - zdravý pohyb</li>
            <li>cvič kdykoliv, kdekoliv, ne! jakkoliv</li>
            <li>Elixír studio je leader zdravého pohybu již 17. rok</li>
          </ul>
<!--          <p>V něm Vám představujeme knihovnu více jak <strong class="pink&#45;&#45;text" v-html="`180&nbsp;předtočených videí`" /> z široké škály cvičebních stylů natočených profesionálními lektory ze všech koutů naší země. Vyberte si své členství.</p>-->
        </div>
        <div class="col w-full xl:w-[68%] mb-0 text-left">
          <v-img :src="require('../assets/welcome.png')" max-width="100%" class="mx-auto"/>
        </div>
      </v-row>
    </v-container>

<!--    <v-container grid-list-lg class="my-12">-->
<!--      <CalendarBanner :href="`/#plans`" />-->
<!--    </v-container>-->

    <Subscription/>

    <ImageCategoryPromo/>

    <Lectors/>

    <v-container grid-list-lg class="mt-12 mb-0">
      <v-row :justify="`center`" class="my-12">
        <v-col cols="12" md="3" v-for="(item,index) in promo" :key="index" class="promo mt-sm-0 mt-5 mb-16 mb-md-0">
          <v-card class="relative-card fill-height" elevation="3">
            <v-card-title class="absolute-icon">
              <v-btn dark large elevation="0" fab color="primary">
                <v-icon dark>{{ item.icon }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="text-center grow">
              <h4 class="my-4">{{ item.title }}</h4>
              <p class="mb-0">{{ item.subtitle }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row :justify="`center`" class="my-12">
        <v-col cols="12" md="3" v-for="(item,index) in promo2" :key="index" class="promo mt-sm-0 mt-5 mb-16 mb-md-0">
          <v-card class="relative-card fill-height" elevation="3">
            <v-card-title class="absolute-icon">
              <v-btn dark large elevation="0" fab color="primary">
                <v-icon dark>{{ item.icon }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="text-center grow">
              <h4 class="my-4">{{ item.title }}</h4>
              <p class="mb-0">{{ item.subtitle }}</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container grid-list-md class="mt-0 mb-0 pb-0" id="faq">
      <v-row class="mt-12">
        <v-col cols="12" sm="6" class="pb-0" v-if="$vuetify.breakpoint.smAndUp">
          <v-img :src="require('../assets/faq-01-494x823.png')" max-width="360" class="mx-auto image-lady"/>
        </v-col>
        <v-col cols="12" sm="6">
          <SectionTitle title="Často kladené dotazy" align="text-left" :margin-bottom="false"/>
          <v-expansion-panels class="mt-3">
            <v-expansion-panel
                v-for="(item,i) in faqs"
                :key="i"
            >
              <v-expansion-panel-header>
                {{ item.title }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.text }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <p>&nbsp;</p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import ImageCategoryPromo from "../components/HomePage/ImageCategoryPromo";
import SectionTitle from "@/components/HomePage/SectionTitle";
import Subscription from "@/components/HomePage/Subscription";
import Lectors from "@/components/HomePage/Lectors";

export default {
  name: 'Homepage',
  components: {
    ImageCategoryPromo,
    SectionTitle,
    Subscription,
    Lectors
  },
  computed: {
    isDesktop() {
      //console.log(this.$vuetify.breakpoint);
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isMobile() {
      //console.log(this.$vuetify.breakpoint.smAndUp);
      return !this.$vuetify.breakpoint.mdAndUp;
    },
  },
  data() {
    return {
      slides: [
        {
          poster: 'header_milovat_pohyb.jpg',
          video: 'header_milovat_pohyb.mp4',
          headingTop: false,
          title: 'Milovat pohyb?<br /><strong class="text-pink">Snadné!</strong>',
        },
        {
          poster: 'header_kdykoliv.jpg',
          video: 'header_kdykoliv.mp4',
          headingTop: true,
          title: 'Rýsovat křivky?<br /><strong class="text-pink">Snadnější!</strong>',
        },
        {
          poster: 'header_zada.jpg',
          video: 'header_zada.mp4',
          headingTop: false,
          title: 'Záda bez bolesti?<br /><strong class="text-pink">Snadnější!</strong>',
        },
        {
          poster: 'header_aktivni.jpg',
          video: 'header_aktivni.mp4',
          headingTop: true,
          title: 'Aktivní po celý život?<br /><strong class="text-pink">Snadné!</strong>',
        },
        {
          poster: 'header_milovat_telo.jpg',
          video: 'header_milovat_telo.mp4',
          headingTop: false,
          title: 'Milovat své tělo?<br /><strong class="text-pink">Snadné!</strong>',
        },
      ],
      slidesMobile: [
        {
          poster: 'header_aktivni.jpg',
          video: 'header_aktivni.mp4',
          headingTop: true,
          title: 'Aktivní po celý život?<br /><strong class="text-pink">Snadné!</strong>',
        },
        {
          poster: 'header_milovat_pohyb.jpg',
          video: 'header_milovat_pohyb.mp4',
          headingTop: false,
          title: 'Milovat pohyb?<br /><strong class="text-pink">Snadné!</strong>',
        },
        {
          poster: 'header_kdykoliv.jpg',
          video: 'header_kdykoliv.mp4',
          headingTop: true,
          title: 'Rýsovat křivky?<br /><strong class="text-pink">Snadnější!</strong>',
        },
        {
          poster: 'header_zada.jpg',
          video: 'header_zada.mp4',
          headingTop: false,
          title: 'Záda bez bolesti?<br /><strong class="text-pink">Snadnější!</strong>',
        },
        {
          poster: 'header_milovat_telo.jpg',
          video: 'header_milovat_telo.mp4',
          headingTop: false,
          title: 'Milovat své tělo?<br /><strong class="text-pink">Snadné!</strong>',
        },
      ],
      promo: [
        {
          icon: 'mdi-medical-bag',
          title: 'Zdravotní aspekt',
          subtitle: 'Bolesti zad jako běžný standard? Pro nás rozhodně ne! Již 15 let jsme průvodci na cestě k lepšímu držení těla a k úlevě zad a kloubů od bolesti. Zdravotní aspekt je u nás na první místě a my se jej snažíme prosadit i v "online" světě.'
        },
        {
          icon: 'mdi-emoticon-wink',
          title: 'Laskavý přístup',
          subtitle: 'Každý z nás je jedinečný, je výjimečný! Jedno máme společné - krok po kroku dokážeme měnit své pohybové návyky a učíme se vnímat své tělo a milovat jej. Přidej se k naší partě, která tě v této změně povzbudí a podpoří. Brzy si této "proměny" všimneš nejen ty, ale i tvé okolí.'
        },
        {
          icon: 'mdi-star-shooting',
          title: 'TOP Lektoři',
          subtitle: 'Naši lektoři posbírali ve své specializaci roky zkušeností, jsou vyškolení a neustále se vzdělávají i pod záštitou Elixír Akademie (akreditované středisko pro vzdělávání lektorů).'
        }
      ],
      promo2: [
        {
          icon: 'mdi-movie-open',
          title: 'Kvalitní produkce',
          subtitle: 'Technické zázemí natáčecího studia neustále vylepšuje. Víme, že s kvalitním zvukem a obrazem se cvičí s větší chutí. Natáčíme až 5 kamerami v rozlišení 4K. A to už stojí za to:)'
        },
        {
          icon: 'mdi-heart',
          title: 'WOW nabídka',
          subtitle: 'U nás si vybere každý - ať začátečník, či ve "formě", těhulka, či maminka po porodu, malí i velcí, při těle i hubení, mladí i v letech, milovníci směrů "body and mind" i dynamických lekcí a tanečních stylů - v naší knihovně najde vhodné cvičení každý. A pokud přece jenom ne, dejte nám vědět a my Vám takovou lekci "přineseme" až k Vám domů :)'
        },
      ],
      faqs: [
        {
          title: 'Jak si mohu aktivovat členství?',
          text: 'Na hlavní stránce našeho portálu Elixironline.cz si z nabídky vyberete opakované členství měsíční, čtvrtletní nebo roční. Kliknete na tlačítko KOUPIT a objeví se formulář Registrace nového zákazníka. Po jeho vyplnění kliknete na tlačítko s cenou vybraného členství a budete přesměrováni na platební bránu GoPay. Zde bezpečně zaplatíte online platební kartou a obratem na Vaši emailovou adresu uvedenou v registraci obdržíte informace s přístupovými údaji k vašemu účtu. Vy tak můžete téměř okamžitě začít neomezeně využívat naši knihovnu videí, účastnit se našich výzev, tréninkových plánů a živých online lekcí dle kalendáře online živých lekcí po celou dobu, kdy je Vaše členství aktivní.',
        },
        {
          title: 'Jaké platební metody mohu použít?',
          text: 'Nejjednodušší, nejrychlejší a přitom bezpečnou metodou je online platba přes platební bránu GoPay. V případě zájmů platby bankovním převodem nás prosím kontaktujte na info@elixironline.cz.\n',
        },
        {
          title: 'Po odeslání platby mi nepřišly přístupové údaje k aktivaci mého účtu',
          text: 'Pokud platíte online platební kartou, je doba obdržení přístupových údajů automatická a obratem. Pokud platíte bankovním převodem, může být tato doba  1-3 dny (poté, co obdržíme platbu). Pokud Vám přesto přístupové údaje nepřišly, zkontrolujte, zda je v objednávce správně zadaná Vaše e-mailová adresa či přístupové údaje nespadly do spamu. Pokud máte jakékoliv technické potíže, volejte prosím telefon 606244876, rádi Vám pomůžeme. ',
        },
        {
          title: 'Brzy bude platnost mého zakoupené členství končit.',
          text: 'Pokud jste zaplatili online svou platební kartou jeden z typů opakované členství, je tato částka fixní po celou dobu aktivního členství e frekvence strhávání fixní částky na další období je 1 den před ukončením placeného členství. Opakované členství můžete kdykoliv zrušit ve svém profilu kliknutím na odkaz Zrušit členství. Pokud jste platili bankovním převodem, platnost vašeho členství pečlivě sledujeme. Před koncem platnosti členství obdržíte e-mail, ve kterém Vám tuto skutečnost oznámíme. Vy si pak můžete zakoupit členství na další období a i nadále (bez přerušení) neomezeně využívat naše online služby cvičení. Pokud si členství neprodloužíte, Vaše členství končí automaticky dnem, kdy končí jeho platnost. ',
        },
        {
          title: 'Chci přejít na jiný typ členství.',
          text: 'V tom případě si objednáte a zaplatíte nový typ členství - toto členství bude aktivní po celé nově zaplacené období. Pokud byste předchozí členství “časově nevyčerpali”, nové členství Vám o tuto dobu prodloužíme. Nepřijde tak nazmar ani jeden den:).'
        },
        {
          title: 'Členství jsem po skončení jeho platnosti neobnovil/a, ale nyní bych ho chtěl/a znova zakoupit.',
          text: 'Moc nás potěší, že s námi budete opět cvičit - stačí si vybrat nové členství, objednat jej a zaplatit a obratem se připojit k naší báječné partě cvičících z celé republiky i světa.'
        },
        {
          title: 'Jak to tady funguje. Přidávají se pravidelně nová cvičební videa?',
          text: 'Ano, do naší knihovny videí přibývají každý týden nová videa, která vedou profesionální lektoři z celé republiky. Neustále natáčíme delší (až hodinová) i kratší (20-40 minut) videa, seriály balíčků (skládají se  z 5-6 kratších videí cca 10-15 minut - z úvodu, rozcvičky a několika hlavních videí zaměřených na různé části těla), tréninkové plány na celý týden, i krátká express videa (např. do kanceláře).'
        },
        {
          title: 'Jak začít? Jaké pomůcky potřebuji?',
          text: 'Prostě začněte. To je to nejdůležitější :). Videa jsou rozdělena podle stupně obtížnosti, tedy začínejte od toho “lehčího” a postupně si můžete přidávat intenzitu cvičení. Vyberte si cvičení, které Vás baví, a brzy jistě v našich videích objevíte lektora, který je Vaší krevní skupinou :). Většinou při cvičení není třeba žádná pomůcka, pokud ano, je to v popisku videa poznamenáno. Pomůcky lze často nahradit předmětem z domácnosti - například jóga cihličku nahradíte složeným ručníkem či knihou, činku zastoupí naplněná PET lahve s vodou. Ovšem nejlepší je cvičit bez pomůcek, využívat váhy svého těla a cvičit vědomě, soustředěně, s láskou k sobě a svému tělu. Potřebujete jen podložku, ručník a pohodlné oblečení. Často cvičíme bez bot (pilates, jóga), někdy je třeba pevná obuv.',
        },
        {
          title: 'Které cvičení je vhodné právě pro mě?',
          text: 'Určitě takové, které vás baví a které stupněm obtížnosti odpovídá tomu, jak jste pokročilí cvičenci. Také jaký je Váš aktuální zdravotní stav - zda Vás nepobolívají záda či nejste v rekonvalescenci. Pamatujte, že méně je někdy více a že i pět minut cvičení je více než necvičit vůbec.',
        },
        {
          title: 'Jsem těhotná či maminka po porodu, jak mám začít cvičit?',
          text: 'V našich lekcích jsou speciální lekce pro těhotné a maminky po porodu. Připomínáme, že budoucí maminky by v prvním trimestru neměly cvičit VŮBEC (ani podle našich videí), zejména, pokud před těhotenstvím necvičily. Vnímejte své tělo a v případě jakékoliv bolesti nebo diskomfortu, cvičení neprodleně přerušte.'
        },
        {
          title: 'Trpím bolestmi zad, které cvičení je pro mě vhodné?',
          text: 'Naše lekce mají i zdravotní aspekt, ale v žádném případě nenahrazují návštěvu lékaře či rehabilitační péči. Mají význam prevence proti bolestem zad a kloubů a jsou účinným pomocníkem v post-rehabilitační péči. Díky pravidelnému cvičení se postupně můžete zbavit či zmírnit bolesti zad a kloubů. Pokud jste na vážkách, zda a co můžete cvičit, poraďte se se svým lékařem. Naši lektoři jsou na špičkové úrovni a jsou neustále proškolováni zkušenými lektory Elixír akademie, aby předcvičovali “zdravě”.',
        },
        {
          title: 'Stále si nejsem jistý, co a jak cvičit. Můžu si s Vámi objednat konzultaci?',
          text: 'Ano, v rámci zakoupeného čtvrtletního a ročního členství můžete využít Zdarma jednu 30minutovou online konzultaci s naším lektorem, specialistou na pohyb a zdravá záda. Stačí nám napsat svůj požadavek na info@elixironline.cz a my s Vámi konzultaci naplánujeme. Vyhrazujeme si právo, tyto konzultace ukončit či časově posunout, pokud by jejich počet byl nad rámec našich momentálních časových možností. Pokud byste měli zájem o osobní konzultaci v prostorech Elixír studia, bude tato osobní konzultace s lektorem zpoplatněna jako individuální lekce  částkou 900 Kč/55 minut.'
        },
        {
          title: 'Chtěli byste pro zakoupení členství využít zaměstnanecké benefity?',
          text: 'Kontaktujte nás na info@elixironline.cz či 603447755, Vše s Vámi zorganizujeme.'
        },
        {
          title: 'Máte jiný dotaz?',
          text: 'Pokud máte jakýkoliv další dotaz, napište nám na email info@elixironline.cz či nám zavolejte na 603447755 nebo 606 244876, rádi Vaše dotazy zodpovíme.'
        },
      ]
    }
  },
}
</script>

<style lang="scss">
.cover .v-btn {
  max-width: 300px !important;
}

.image-lady {
  position: absolute;
  bottom: 0;
  left: 100px;
}

.relative-card {
  position: relative;
  padding: 42px 15px 27px 15px;
  border-radius: 24px !important;
}

.absolute-icon {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.v-parallax__content {
  padding: 0;
}

.promo .relative-card {
  box-shadow: 0 12px 15px 0 rgba(209, 26, 111, 0.22) !important;
}

.promo h4 {
  margin-bottom: 10px;
  font-size: 19px;
  font-weight: 500;
  color: #d11a6f;
  text-transform: uppercase;
}

.promo p {
  line-height: 1.31;
  font-size: 13px;
  color: #707070;
}

.list-dots {
  list-style: disc;
}

.list-dots li {
  padding-left: 10px;
  margin-bottom: 4px;
}
</style>
