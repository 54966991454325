<template>
    <v-container class="pt-8 pl-0 footer-address">
      <h4 class="white--text text-uppercase">Kontaktní informace</h4>
        <v-list dense class="transparent" dark>
            <v-list-item class="pl-0">
                <v-list-item-icon class="mr-1">
                    <v-icon color="grey" small>mdi-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Holšická 1327, Praha 9</v-list-item-title>
            </v-list-item>
            <v-list-item class="pl-0">
                <v-list-item-icon class="mr-1">
                    <v-icon color="grey" small>mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Telefon: +420 603 44 77 55</v-list-item-title>
            </v-list-item>
            <v-list-item class="pl-0">
                <v-list-item-icon class="mr-1">
                    <v-icon color="grey" small>mdi-mail</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Email: info@elixironline.cz</v-list-item-title>
            </v-list-item>
            <v-list-item class="pl-0">
                <v-list-item-icon class="mr-1">
                    <v-icon color="grey" small>mdi-card-bulleted-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Provozovatelem webu je Elixír studio s.r.o.<br />IČ: 06994300<br />DIČ: CZ 06994300</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-container>
</template>

<script>
    export default {
        name: 'FooterAddress'
    }
</script>

<style lang="scss">
    .footer-address {
        .custom-logo {
            max-width: 180px;
        }
    }
</style>
