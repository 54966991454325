<template>
  <div>
    <div class="container">
      <div class="heading">
        <h2 class="mt-2 text-lg font-bold">Výsledky vyhledávání</h2>
      </div>
      <!-- Apollo watched Graphql query -->
      <ApolloQuery
          :query='gql => gql`query MyQuery($keywords: String) {entries(type: "videos", search: $keywords) { id, title, ... on videos_videos_Entry { difficulity, tags {id, title}, videoId, embedCode, thumbnail { id,url }, prioritisedCategory { ... on prioritisedCategory_category_BlockType { id categories { id } priority}}} } }`'
          :variables="{ keywords }">
        <template slot-scope="{ result: { loading, error, data } }">
          <!-- Loading -->
          <div v-if="loading" class="loading apollo">Loading...</div>
          <!-- Error -->
          <div v-else-if="error" class="error apollo">An error occured</div>
          <div v-else-if="data" class="grid grid-cols-4">
            <div v-for="(video, key) in data.entries" style="padding: 1rem;" :key="key">
              <single-video  :video="video" />
            </div>
          </div>
        </template>
      </ApolloQuery>
    </div>
  </div>
</template>


<script>

import {mapGetters, mapActions} from 'vuex'
import gql from "graphql-tag";
import SingleVideo from "../components/Elixir/SingleVideo";

export default {
  name: 'SearchPage',
  data() {
    return {
      keywords: null,
    }
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    entries: gql`query MyQuery {
  entries(type: "categories") {
    id,
    title
  }
}`,
  },
  metaInfo() {
    return {
      title: 'Elixír Active',
    }
  },
  watch: {
    loadingCategories: function (newValue) {
      if (!newValue) {
        this.changeCategory();
      }
    }
  },
  mounted() {
    this.keywords = this.$router.currentRoute.query.q;
    this.changeCategory();
  },
  components: {
    SingleVideo
  },
  computed: {
    ...mapGetters([
      'allProducts',
      'currentCategory',
      'loadingCategories'
    ])
  },
  methods: {
    ...mapActions([
      'changeCategory'
    ])
  }
}
</script>
