<template>
    <v-form v-model="valid" ref="checkoutForm" :lazy-validation="false" @submit.prevent="handleFormSubmit" @keyup.native.enter="submit($event)">
        <v-container class="checkout">
            <v-row>
                <v-col cols="12" sm="6">
                    <delivery-address :form="form" />
                    <delivery-methods :form="form" />
                    <payment-methods :form="form" />
                </v-col>
                <v-col cols="12" sm="6">
                      <summary-block :form="form" />
                      <place-order :valid="valid" :form="form" :loading="loading" @submit="submit" />
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import DeliveryAddress from "../components/Checkout/DeliveryAddress";
    import Summary from '../components/Checkout/Summary'
    import DeliveryMethods from "../components/Checkout/DeliveryMethods";
    import PaymentMethods from "../components/Checkout/PaymentMethods";
    import PlaceOrder from "../components/Checkout/PlaceOrder";

    export default {
        name: 'Checkout',
        metaInfo () {
            return {
                title: 'Pokladna',
            }
        },
        components: {
            DeliveryAddress,
            'summary-block': Summary,
            DeliveryMethods,
            PaymentMethods,
            PlaceOrder
        },
        data () {
            return {
                form: {
                    customer: {
                        firstName: '',
                        lastName: '',
                        recipientName: ''
                    },
                    plainPassword1: '',
                  plainPassword2: ''
                },
                valid: true,
                loading: false
            }
        },
        computed: {
            ...mapGetters([
                'cartProducts',
                'user',
                'paymentMethods',
                'paymentMethodsLoaded',
                'shippingMethods',
                'shippingMethodsLoaded'
            ]),
            checkoutCartItems() {
                let items = [];
                if (this.cartProducts[0] === null) return null;
                for (let i in this.cartProducts) {
                    let item = {
                        productId: this.cartProducts[i].productId,
                        qty: this.cartProducts[i].qty
                    };

                    if (this.cartProducts[i].giftCard) {
                        item.giftCard = {
                            name: this.cartProducts[i].giftCard.name,
                            text: this.cartProducts[i].giftCard.text
                        }
                    }

                    items.push(item);
                }
                return items;
            }
        },
        watch: {
            shippingMethodsLoaded: function (val) {
                if (val && this.shippingMethods.length) {
                    this.form.shippingMethodId = this.shippingMethods[0].id;
                }
            },
            paymentMethodsLoaded: function (val) {
                if (val && this.paymentMethods.length) {
                    this.form.paymentMethodId = this.paymentMethods[0].id;
                }
            }
        },
        beforeMount() {
            this.form.customer = {
                firstName: '',
                lastName: ''
            };
            this.form.customer.address = {};
            if (this.user) {
                this.form.customer.firstName = this.user.firstName;
                this.form.customer.lastName = this.user.lastName;
                this.form.customer.email = this.user.email;
                this.form.customer.phone = this.user.phone;
            }
            if (this.shippingMethodsLoaded && this.shippingMethods.length) {
                this.form.shippingMethodId = this.shippingMethods[0].id;
            }

            if (this.paymentMethodsLoaded && this.paymentMethods.length) {
                this.form.paymentMethodId = this.paymentMethods[0].id;
            }
        },
        mounted() {
            if (!this.cartProducts.length) {
                this.$router.push('/');
            }
        },
        methods: {
            ...mapActions([
                'handleFormSubmit',
                'setOrderId',
                'emptyCart'
            ]),
            submit(event) {
                event.preventDefault();
                if (this.$refs.checkoutForm.validate()) {
                    this.loading = true;
                    this.form.eshopId = process.env.VUE_APP_API_ESHOP_ID;
                    this.form.items = this.checkoutCartItems;
                    this.form.password = this.form.plainPassword1;
                    window.axios.post(process.env.VUE_APP_ROOT_API + process.env.VUE_APP_API_CART, this.form).then(response => {
                        this.onSuccess(response)
                    }).catch(error => {console.log(error)});
                }
            },
            onSuccess(response) {
                this.setOrderId(response.data);
                this.emptyCart();
                this.loading = false;
                if (response.data.paymentUrl) {
                    window.location.replace(decodeURI(response.data.paymentUrl));
                } else {
                    this.$router.push('/checkout/success');
                }
            }
        }
    }
</script>

<style lang="scss">
    .checkout {
        .checkout-section-title {
            font-size: 22px;
            font-weight: 600;
            text-transform: uppercase;
            color: #000000;
        }
    }
</style>
