<template>
  <div>
    <div class="container mb-6 mt-6">
      <div class="heading">
        <h2 class="text-32 pb-5">Správa přístupů do Elixir Active</h2>
        <p>
          <strong v-if="user">Účet: {{ user.companyName }}</strong>
        </p>

        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="flex flex-col">
          <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                  <tr>
                    <th scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      Datum posledního přihlášení
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      Expirace účtu
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Smazat</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <!-- Odd row -->
                  <!--<tr class="bg-white">
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      jane.cooper@example.com
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a href="#" class="text-indigo-600 hover:text-indigo-900">Smazat</a>
                    </td>
                  </tr>-->

                  <!-- Even row -->
                  <tr class="bg-gray-50" v-for="(user, key) in users" v-bind:key="key">
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ user.email }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ user.date }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ expiration(user) }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <a @click="deleteUser(user.id)" href="#" class="text-indigo-600 hover:text-indigo-900">Smazat</a>
                    </td>
                  </tr>

                  <!-- More people... -->
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- This example requires Tailwind CSS v2.0+ -->
        <span class="relative z-0 inline-flex shadow-sm rounded-md">
  <button type="button" @click="importScreen = false; singleScreen = true;"
          class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-pink mr-2 text-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
    Přidat uživatele
  </button>
  <button type="button" @click="importScreen = true; singleScreen = false;"
          class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-pink mr-2 text-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
    Importovat seznam e-mailů
  </button>
</span>

        <div v-if="importScreen">
          <h3 class="font-bold pt-4">Importovat:</h3>

          <div class="sm:col-span-6">
            <label for="about" class="block text-sm font-medium text-gray-700">
              Zadejte seznam e-mailových adres
            </label>
            <div class="mt-1">
              <textarea id="about" v-model="newEmailsArea" name="about" rows="3"
                        style="border: 1px solid #d11a6f; border-radius: 5px;"
                        class="shadow-sm p-4 focus:ring-black focus:border-black block w-full sm:text-sm border border-black rounded-md"></textarea>
            </div>
            <p class="mt-2 text-sm text-gray-500">(každou na nový řádek)</p>

            <button type="button" @click="importEmails()"
                    class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-pink mr-2 text-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              Odeslat
            </button>
          </div>


        </div>

        <div v-if="singleScreen">
          <h3 class="font-bold pt-4">Přidat nový účet:</h3>

          <div class="sm:col-span-6">
            <label for="about" class="block text-sm font-medium text-gray-700">
              Zadejte e-mail uživatele, na který mu přijde pozvánka:
            </label>
            <div class="mt-1" style="display: flex;">
              <input id="about" v-model="newEmail" name="about" rows="3"
                     style="border: 1px solid #d11a6f; border-radius: 5px;"
                     class="shadow-sm p-2 focus:ring-black focus:border-black block w-full sm:text-sm border border-black rounded-md"/>
              <select style="margin-left: 1rem; border: 1px solid black; -webkit-appearance: menulist !important; appearance: menulist;" class="period" v-model="period">
                <option v-for="period in periods" :value="period.value" :key="period.value">
                  {{ period.text }}
                </option>
              </select>
            </div>

            <button type="button" @click="addNewEmail()"
                    class="relative mt-2 inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-pink mr-2 text-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              Odeslat
            </button>
          </div>


        </div>


      </div>
    </div>
  </div>
</template>

<style>
.container {
  @apply px-0;
}

.heading {
  @apply px-20;
}

img {
  height: 205px;
  @apply w-full object-cover rounded shadow overflow-hidden mb-15;
}

h2 {
  @apply text-black uppercase font-500 text-32 font-fira leading-tight mb-5;
}

h3 {
  @apply text-black uppercase font-500 text-base font-fira leading-tight mb-0;
}

.videos.light h3, .videos.light h2 {
  @apply text-white;
}
</style>

<script>

import {mapGetters, mapActions} from 'vuex'
import gql from "graphql-tag";

export default {
  name: 'CategoryPage',
  data() {
    return {
      importScreen: false,
      newEmail: null,
      users: [],
      newEmailsArea: null,
      singleScreen: false,
      period: 'monthly',
      periods: [
        {text: '1 měsíc', value: 'monthly'},
        {text: '3 měsíce', value: 'quarterly'},
        {text: '12 měsíců', value: 'yearly'},
      ],
    }
  },
  apollo: {
    // Simple query that will update the 'hello' vue property
    entries: gql`query MyQuery {
  entries(type: "categories") {
    id,
    title
  }
}`,
  },
  metaInfo() {
    return {
      title: 'Elixír Active',
    }
  },
  watch: {
    loadingCategories: function (newValue) {
      if (!newValue) {
        this.changeCategory();
      }
    }
  },
  mounted() {
    this.loadUsers();
  },
  computed: {
    ...mapGetters([
      'allProducts',
      'user',
      'currentCategory',
      'loadingCategories'
    ])
  },
  methods: {
    ...mapActions([
      'changeCategory'
    ]),
    expiration(user) {
      console.log(user.expires);
      if (user.expires_at) {
        return new Date(user.expires_at).toLocaleDateString();
      }
      return '-';
    },
    deleteUser(id) {
      if (window.confirm("Opravdu si přejte smazat tento email ?")) {
        window.axios.delete('https://api.elixironline.cz/api/account/' + id).then(response => {
          this.users = response.data.data;
        });
      }

      return false;
    },
    loadUsers() {
      window.axios.post('https://api.elixironline.cz/api/account/users?id=' + localStorage.getItem('company_id')).then(response => {
        this.users = response.data.data;
      });
    },
    createUser(email) {
      window.axios.post('https://api.elixironline.cz/api/account/users/create', {
        email: email,
        period: this.period,
      }).then(response => {
        this.users = response.data.data;
      });
    },
    addNewEmail() {
      this.createUser(this.newEmail);
      this.newEmail = null;
    },
    importEmails() {
      let items = this.newEmailsArea.split("\n");
      for (let i in items) {
        this.createUser(items[i]);
      }
      this.newEmailsArea = null;
    }
  }
}
</script>
