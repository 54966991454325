<template>
  <a target="_blank" :href="item.zoomUrl" class="item">
    <div class="icon">
      <svg xmlns="http://www.w3.org/2000/svg" width="26.261" height="38" viewBox="0 0 26.261 38">
        <g id="dancing" transform="translate(-79.092)">
          <g id="Group_49" data-name="Group 49" transform="translate(79.092 0)">
            <path id="Path_29" data-name="Path 29"
                  d="M105.018,34.629l-3.573-5.68a.742.742,0,0,0-1.256.79L101.9,32.46l-1.5,1.031c-3.773-4.817-9.758-12.453-9.758-12.453a.742.742,0,0,0-1.052-.118l-4.67,3.8a.742.742,0,0,0-.229.829L86.9,31.616l-1.888.858-2.5-4.987a3.876,3.876,0,0,1,.523-4.265l4.635-5.413,4.68-.9a4.346,4.346,0,0,1,2.675,4.356.742.742,0,0,0,.113.438l1.5,2.379a.742.742,0,0,0,1.256-.79l-1.374-2.185a5.868,5.868,0,0,0-3.347-5.455l.033-2.949a2.544,2.544,0,0,0,.775-2.925,3.148,3.148,0,0,0,3.171-5.226h5.35A2.278,2.278,0,1,0,102.5,0H96.164A7.972,7.972,0,0,0,90.44,2.44l-3.58,3.729a3.488,3.488,0,0,0-1.494,2.676L82.1,7.8a2.288,2.288,0,0,0-2.871,1.388,2.278,2.278,0,0,0,1.431,2.934l3.763,1.413a12.69,12.69,0,0,0,1.527.381l.447,3.1-4.49,5.243a5.357,5.357,0,0,0-.722,5.9l4.3,8.58A2.274,2.274,0,0,0,87.517,38a2.311,2.311,0,0,0,.858-.166,2.279,2.279,0,0,0,1.3-2.908l-3.413-9.389,3.674-2.989c1.885,2.405,8.959,11.433,11.435,14.595a2.22,2.22,0,0,0,3.024.444l.029-.02a2.173,2.173,0,0,0,.592-2.937ZM86.846,9.189a2.219,2.219,0,0,1,.221-1.069,2,2,0,0,1,1.78-1.085h.009l2.922.012a3.125,3.125,0,0,0,.341,1.175,2.547,2.547,0,0,0-1.736.26,6.114,6.114,0,0,1-.96.42A6,6,0,0,1,86.846,9.189Zm8.068-.749A1.665,1.665,0,1,1,96.58,6.775a1.667,1.667,0,0,1-1.665,1.665Zm-3.4-4.973a6.481,6.481,0,0,1,4.653-1.983H102.5a.793.793,0,0,1,0,1.587H95.678a3.244,3.244,0,0,0-2.29.95,3.169,3.169,0,0,0-1.381,1.542l-2.5-.011ZM81.165,10.729l-.035-.012a.794.794,0,0,1-.505-1.025.82.82,0,0,1,1.023-.475L85.2,10.35a7.467,7.467,0,0,0,4.7-.042,7.606,7.606,0,0,0,1.194-.523,1.067,1.067,0,0,1,1.421.376h0a1.066,1.066,0,0,1-.4,1.5,8.241,8.241,0,0,1-4.311.989,11.658,11.658,0,0,1-2.909-.518Zm6.562,3.4q.234.012.467.012a9.683,9.683,0,0,0,3.514-.665l-.023,2.053-3.9.746-.312-2.162c.083.006.167.012.25.016Zm.1,22.326a.8.8,0,0,1-1.018-.387L85.672,33.8l1.732-.787.879,2.417a.8.8,0,0,1-.458,1.022Zm15.749-.1-.029.02a.736.736,0,0,1-1-.144l-1.227-1.566,1.376-.944,1.071,1.7a.689.689,0,0,1-.187.932Z"
                  transform="translate(-79.092 0)" fill="#fff"/>
            <circle id="Ellipse_18" data-name="Ellipse 18" cx="0.742" cy="0.742" r="0.742"
                    transform="translate(19.204 25.776)" fill="#fff"/>
          </g>
        </g>
      </svg>
    </div>
    <div class="text">
            <span class="time">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                <path id="clock_2_" data-name="clock (2)"
                      d="M6,0a6,6,0,1,0,6,6A6.006,6.006,0,0,0,6,0ZM8.853,9.1a.5.5,0,0,1-.707,0l-2.5-2.5A.5.5,0,0,1,5.5,6.25V3a.5.5,0,1,1,1,0V6.043L8.853,8.4A.5.5,0,0,1,8.853,9.1Zm0,0"
                      fill="#d11a6f"/>
              </svg>
              {{ time }}
            </span>
      <span v-show="false" class="availability">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                <g id="Group_71" data-name="Group 71" transform="translate(-1.588 -1.588)">
                  <path id="Path_31" data-name="Path 31"
                        d="M7.587,1.588a3.143,3.143,0,1,0,3.144,3.143A3.147,3.147,0,0,0,7.587,1.588ZM5.349,7.573a.286.286,0,0,0-.078.009c-2.222.569-3.683,1.936-3.683,3.434v1.143a.286.286,0,0,0,.158.256,14.206,14.206,0,0,0,5.842,1.173,14.206,14.206,0,0,0,5.842-1.173.286.286,0,0,0,.158-.256V11.016c0-1.5-1.462-2.865-3.684-3.434a.286.286,0,0,0-.238.045,3.538,3.538,0,0,1-4.157,0A.286.286,0,0,0,5.349,7.573Z"
                        fill="#d22c6f" fill-rule="evenodd"/>
                </g>
              </svg>
              15/16
            </span>
      <h4 v-text="item.title" />
      <div class="flex-initial w-[1px]">&nbsp;</div>
      <span class="button">
                Připojit se k lekci
              </span>
    </div>
  </a>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    selectToday() {
      this.$parent.selectDate(this.date);
    }
  },
  computed: {
    time() {
      let mins = parseInt((new Date(this.item.date)).getMinutes());
      if (mins < 10) mins = '0' + mins;
      return (new Date(this.item.date)).getHours() + ':' + mins;
    },

    day() {
      return this.date.getDate();
    },
  }
}
</script>
<style scoped>
.item {
  @apply relative w-full border-b border-[#ececec] block pl-25 pr-0 xl:px-30 py-[23px] flex no-underline;
}

.item:before {
  content: "";
  height: calc(100% - 46px);
  @apply absolute top-[23px] left-0 block w-[4px] bg-[#d11a6f];
}

.item svg {
  @apply inline-block flex-initial mr-[6px] relative top-[-1px];
}

.item .icon {
  @apply bg-[#d11a6f] flex-none w-[60px] h-[60px] rounded-[12px] text-center flex items-center;
}

.item .icon svg {
  @apply mx-auto top-auto flex-initial max-w-[90%] max-h-[80%];
}

.item .text {
  @apply flex-1 pl-[14px] flex justify-between flex-wrap;
}

.availability {
  @apply text-14 text-[#202020] font-bold flex-initial flex items-center;
}

.time {
  @apply text-14 text-[#202020] flex-initial flex items-center;
}

h4 {
  @apply w-full block flex-none font-bold text-base text-[#202020] mb-[12px] leading-tight pt-5;
}

.button {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  @apply rounded text-white text-14 font-bold uppercase px-[14px] pt-[3px] pb-[2px] bg-[#d11a6f] flex-initial justify-self-end;
}

.item.disabled {
  @apply opacity-50;
}

</style>