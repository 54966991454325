<template>
    <v-container fluid class="page-bg pt-5" :style="{'background-image': 'url(' + bgImage +')'}">
        <v-container>
            <h1 class="black--text main-title">{{ title }}</h1>
            <div v-html="description" />
            <div v-if="showCategories">
                <v-btn @click="$router.push('/category/' + category.alias)" text :color="getButtonColor(category.alias)" v-for="category in allCategories" :key="category.id">{{ category.name }}</v-btn>
            </div>
        </v-container>
    </v-container>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    export default {
        name: 'CategoryHeader',
        props: ['bgImage', 'showCategories'],
        computed: {
            ...mapGetters([
                'currentCategory',
                'allCategories'
            ]),
            title() {
                if (this.currentCategory) {
                    return this.currentCategory.name;
                }

                return 'Obchod';
            },
          description() {
            if (this.currentCategory) {
              return this.currentCategory.description;
            }

            return null;
          },
            activeItem() {
                if (this.currentCategory) {
                    return this.currentCategory.alias;
                }

                return null;
            }
        },
        mounted() {
            let categoryAlias = this.$router.currentRoute.params;
            if (typeof categoryAlias.alias !== 'undefined') {
                this.changeCategory();
            }
        },
        watch: {
            '$route.params.alias': function () {
                this.changeCategory();
            }
        },
        methods: {
            ...mapActions([
                'changeCategory'
            ]),
            getButtonColor(alias) {
                if (alias === this.activeItem) {
                    return 'primary';
                }

                return 'black';
            }
        }
    }
</script>

<style lang="scss">
    .page-bg {
        background-size: cover;
        background-position: center center;
        .main-title {
            font-size: 34px;
            font-weight: 600;
        }
        .v-btn__content {
            font-weight: bold;
        }
    }
</style>
