<template>
  <MainHeader :isCheckout="isCheckout" :isCategory="isCategory" :isDetail="isDetail" />
</template>

<script>
    import MainHeader from "../Header/MainHeader";

    export default {
        name: 'NavBar',
        props: ['isCheckout', 'isCategory', 'isDetail'],
        components: {
            MainHeader
        }
    }
</script>
