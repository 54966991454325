<template>
  <div v-if="ids.length >= 1" class="videos" :class="variant">
    <div class="container">
      <div class="heading">
        <h2>{{ heading }}</h2>
      </div>
      <!-- Apollo watched Graphql query -->
      <ApolloQuery
          :query='gql => gql`query MyQuery($ids: [QueryArgument]) {entries(id: $ids) { id, title, ... on videos_videos_Entry { difficulity, tags {id, title}, videoId, embedCode, thumbnail { id,url }} } }`'
          :variables="{ ids }">
        <template slot-scope="{ result: { loading, error, data } }">
          <!-- Loading -->
          <div v-if="loading" class="loading apollo">Loading...</div>
          <!-- Error -->
          <div v-else-if="error" class="error apollo">An error occured</div>
          <div v-else-if="data">
            <VueSlickCarousel v-bind="settings">
              <single-video :key="key" v-for="(video, key) in data.entries" :video="video" />
            </VueSlickCarousel>
          </div>
        </template>
      </ApolloQuery>
    </div>
  </div>
</template>

<style scoped>

.videos {
  @apply mb-50;
}

.container {
  @apply px-0;
}

.heading {
  @apply px-20;
}

.slide {
  @apply py-20 px-10;
}

img {
  height: 205px;
  @apply w-full object-cover rounded shadow overflow-hidden mb-15;
}

h2 {
  @apply text-black uppercase font-500 text-20 font-fira leading-tight mb-5;
}

h3 {
  @apply text-black uppercase font-500 text-base font-fira leading-tight mb-0;
}

.videos.light h3, .videos.light h2 {
  @apply text-white;
}

</style>

<style>

.videos .slick-slider {
  @apply relative px-10 mb-0;
}

.videos .slick-arrow {
  z-index: 500;
  right: 20px;
  top: -27px;
  @apply absolute bg-transparent border-none left-auto m-0;
}

.videos .slick-arrow:before {
  opacity: 1;
  @apply font-fira font-bold;
  font-size: 30px;
}

.videos .slick-prev:before {
  content: url("../../assets/lt-grey.svg");
}

.videos .slick-next:before {
  content: url("../../assets/gt-grey.svg");
}

.videos .slick-prev:hover:before {
  content: url("../../assets/lt.svg");
}

.videos .slick-next:hover:before {
  content: url("../../assets/gt.svg");
}

.videos .slick-prev {
  right: 55px;
}

.videos.light .slick-prev:before {
  content: url("../../assets/lt-white.svg") !important;
}

.videos.light .slick-next:before {
  content: url("../../assets/gt-white.svg") !important;
}


</style>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import SingleVideo from "@/components/Elixir/SingleVideo";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  data() {
    return {
      settings: {
        "dots": false,
        "arrows": true,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 4,
        "slidesToScroll": 4,
        "responsive": [
          {
            "breakpoint": 1200,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
            }
          },
          {
            "breakpoint": 800,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
            }
          },
          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
            }
          }

        ]
      },

    }
  },
  name: 'SimilarVideos',
  props: ['heading', 'variant', 'ids'],
  components: {
    VueSlickCarousel,
    SingleVideo
  }
};
</script>
