var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-3"},[_vm._v("Moje objednávky")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.orders,"items-per-page":10,"hide-default-footer":"","no-data-text":"Doposud nebyla nalezena žádná objednávka."},scopedSlots:_vm._u([{key:"item.orderDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.orderDate))+" ")]}},{key:"item.totalWithoutVat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalWithoutVat)+" Kč ")]}},{key:"item.totalWithVat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.totalWithVat)+" Kč ")]}},{key:"item.paymentState",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getPaymentStateColor(item.paymentState)}},[_vm._v(" "+_vm._s(_vm.getPaymentState(item.paymentState))+" ")])]}},{key:"item.orderState",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.getOrderStateColor(item.orderState)}},[_vm._v(" "+_vm._s(_vm.getOrderState(item.orderState))+" ")])]}},{key:"item.invoiceUrl",fn:function(ref){
var item = ref.item;
return [(item.invoiceUrl)?_c('span',[_c('v-btn',{attrs:{"icon":"","href":item.invoiceUrl,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-download")])],1)],1):_c('span',[_vm._v(" - ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }