<template>
  <div id="app">
    <v-app>
      <nav-bar :isCheckout="isCheckout" :isCategory="isCategory" :isDetail="isDetail"></nav-bar>
      <v-main>
        <router-view></router-view>
      </v-main>
      <app-footer :isCheckout="isCheckout" />
      <snackbar />
      <cookie />
    </v-app>
  </div>
</template>

<script>
  import Cookie from './components/System/Cookie.vue'
  import NavBar from './components/System/NavBar.vue'
  import Footer from './components/Footer/Footer'
  import Snackbar from "./components/System/Snackbar";
  import {mapActions, mapGetters} from 'vuex'

  export default {
    name: 'app',
    metaInfo: {
      title: 'Vítejte',
      titleTemplate: '%s | Elixír Online'
    },
    components: {
      NavBar,
      'AppFooter': Footer,
      Snackbar,
      Cookie
    },
    computed: {
        ...mapGetters([
                'allCategories'
        ])
    },
    data () {
      return {
        isCheckout: false,
        isCategory: false,
        isDetail: false,
      }
    },
    mounted() {
      this.q = this.$router.currentRoute.query.q;
      this.isCheckout = this.$router.currentRoute.name === 'Checkout' || this.$router.currentRoute.name === 'CheckoutSuccess';
      this.isCategory = this.$router.currentRoute.name === 'Category' || this.$router.currentRoute.name === 'Videos' || this.$router.currentRoute.name === 'Orders';
      this.isDetail = this.$router.currentRoute.name === 'Detail';
      if (!this.allCategories.length) {
        this.retrieveCategories();
      }
    },
    watch: {
      '$route.name': function (val) {
        this.isCheckout = val === 'Checkout' || val === 'CheckoutSuccess';
        this.isCategory = val === 'Category' || val === 'Videos' || val === 'Orders';
        this.isDetail = val === 'Detail';
      }
    },
    methods: {
        ...mapActions([
                'retrieveCategories'
        ])
    }
  }
</script>

<style lang="scss">
  @media (min-width: 1904px) {
    .container {
      max-width: 1185px;
     &.container--fluid {
       max-width: 100%;
     }
    }
  }

  .v-application .grey.darken-4 {
    background-color: #212121 !important;
    border-color: #212121 !important;
    margin: 0;
    width: 100%;
    max-width: 100%;
  }

  .v-application {
    font-family: 'Ubuntu', sans-serif !important;
    background-color: #fbfbfb !important;
    .body-2, .display-4, .headline, .display-1, .subtitle-1, .display-2, .text-h5, .text-h6, .text-h3, .title {
      font-family: 'Ubuntu', sans-serif !important;
    }
  }
</style>
