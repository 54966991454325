<template>
    <div>
      <category-header :show-categories="false" />
        <products />
    </div>
</template>

<script>
    import Products from '../components/Category/Products'
    import CategoryHeader from '../components/Category/CategoryHeader'
    import {mapGetters, mapActions} from 'vuex'

    export default {
        name: 'CategoryPage',
        metaInfo () {
            return {
                title: 'Elixír Active',
            }
        },
        watch: {
            loadingCategories: function (newValue) {
                if (!newValue) {
                  this.changeCategory();
                }
            }
        },
        mounted() {
          this.changeCategory();
        },
        components: {
            Products,
          CategoryHeader
        },
        computed: {
            ...mapGetters([
                'allProducts',
                'currentCategory',
                'loadingCategories'
            ])
        },
        methods: {
            ...mapActions([
                'changeCategory'
            ])
        }
    }
</script>
