<template>
    <v-container fluid class="grey darken-4">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <copyright />



          </v-col>
          <v-col cols="12" sm="6" md="3" v-if="!isCheckout">
            <Address />
          </v-col>
          <v-col cols="12" sm="6" md="3" v-if="!isCheckout">
            <Links />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
</template>

<script>
    import Copyright from "./Copyright";
    import Address from "@/components/Footer/Address";
    import Links from "@/components/Footer/Links";

    export default {
        name: 'Footer',
        props: ['isCheckout'],
        components: {
            Copyright,
          Address,
          Links
        }
    }
</script>
