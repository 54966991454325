<template>
    <v-container class="white--text important-links pt-8">
       <v-layout row wrap>
         <h4>Sledujte nás</h4><br />
           <v-flex xs12>
             <div class="pt-2" style="clear: both">
               <a target="_blank" href="https://www.facebook.com/elixirstudio.cz">
                 <v-icon large color="white" class="mr-2">mdi-facebook</v-icon>
               </a>
               <a target="_blank" href="https://www.instagram.com/elixironline.cz/">
                 <v-icon large color="white" class="mr-2">mdi-instagram</v-icon>
               </a>
<!--               <v-icon large color="white">mdi-youtube</v-icon>-->
             </div>
           </v-flex>
       </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: 'ImportantLinks'
    }
</script>

<style lang="scss">
    .important-links {
        h4 {
            font-weight: 600;
            text-transform: uppercase;
            font-size: 14px;
        }
    }
</style>
