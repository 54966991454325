<template>
  <!-- Apollo watched Graphql query -->
  <ApolloQuery
      :query='gql => gql`query MyQuery($categories: [QueryArgument]) {entries(type: "videos", categories: $categories) { id, title, ... on videos_videos_Entry { difficulity, videoId,vimeoPreviewId, tags {id, title}, embedCode, thumbnail { id,url }, prioritisedCategory { ... on prioritisedCategory_category_BlockType { id categories { id } priority}}} } }`'
      :variables="{ categories }">
    <template slot-scope="{ result: { loading, error, data } }">
      <!-- Loading -->
      <div v-if="loading" class="loading apollo">Loading...</div>
      <!-- Error -->
      <div v-else-if="error" class="error apollo">An error occured</div>
      <div v-else-if="data">
        <div class="videos" :class="variant" v-if="data.entries.length">
          <div class="container no-py">
            <div class="heading">
              <h2>{{ heading }}</h2>
            </div>
            <VueSlickCarousel v-bind="settings">
              <single-video :demo="demo" :key="key" v-for="(video, key) in sortByCategoryPriority(data.entries)" :video="video" />
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </template>
<!--    <div v-if="order == 1">-->
<!--      <v-container grid-list-lg class="max-w-[1500px] my-12">-->
<!--        <CalendarBanner :href="`/calendar/`" />-->
<!--      </v-container>-->
<!--    </div>-->
  </ApolloQuery>
</template>

<style scoped>

.videos {
  @apply mb-20;
}

.no-py {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.container {
  @apply px-0;
}

.heading {
  @apply px-20;
}

.slide {
  @apply py-10 px-10;
}

img {
  height: 205px;
  @apply w-full object-cover rounded shadow overflow-hidden mb-15;
}

h2 {
  @apply text-black uppercase font-500 text-20 font-fira leading-tight mb-5;
}

h3 {
  @apply text-black uppercase font-500 text-base font-fira leading-tight mb-0;
}

.videos.light h3, .videos.light h2 {
  @apply text-white;
}

</style>

<style>

.slick-prev, .slick-next {
  width: 13px;
  height: 36px;
}

.videos .slick-slider {
  @apply relative px-10 mb-0;
}

.videos .slick-arrow {
  z-index: 500;
  right: 20px;
  top: -27px;
  @apply absolute bg-transparent border-none left-auto m-0;
}

.videos .slick-arrow:before {
  opacity: 1;
  @apply font-fira font-bold;
  font-size: 30px;
}

.videos .slick-prev:before {
  content: url("../../assets/lt-grey.svg");
}

.videos .slick-next:before {
  content: url("../../assets/gt-grey.svg");
}

.videos .slick-prev:hover:before {
  content: url("../../assets/lt.svg");
}

.videos .slick-next:hover:before {
  content: url("../../assets/gt.svg");
}

.videos .slick-prev {
  right: 55px;
}

.videos.light .slick-prev:before {
  content: url("../../assets/lt-white.svg") !important;
}

.videos.light .slick-next:before {
  content: url("../../assets/gt-white.svg") !important;
}


</style>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import SingleVideo from "@/components/Elixir/SingleVideo";
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  data() {
    return {
      settings: {
        "dots": false,
        "arrows": true,
        "infinite": true,
        "speed": 500,
        "slidesToShow": 4,
        "slidesToScroll": 4,
        "responsive": [
          {
            "breakpoint": 1200,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
            }
          },
          {
            "breakpoint": 800,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
            }
          },
          {
            "breakpoint": 500,
            "settings": {
              "slidesToShow": 1,
              "slidesToScroll": 1,
            }
          }

        ]
      },

    }
  },
  name: 'Videolist',
  props: ['heading', 'variant', 'categories', 'order', 'demo'],
  methods: {
    sortByCategoryPriority(data) {

      let videos = [];


      for(let i in data) {
        let video = data[i];

        if(!video.vimeoPreviewId && this.demo) {
          continue;
        }

        let priority = 0;

        for(let p in video.prioritisedCategory) {
          for(let c in video.prioritisedCategory[p].categories) {
            if(video.prioritisedCategory[p].categories[c].id == this.categories[0]) {
              priority = parseInt(video.prioritisedCategory[p].priority);
            }
          }
        }

        video.priority = priority;
        videos.push(data[i])
      }

      let videosSorted = videos.sort((a,b) => (a.priority < b.priority) ? 1 : ((b.priority < a.priority) ? -1 : 0))
      console.log(videosSorted);
      return videosSorted;
    },
  },
  components: {
    VueSlickCarousel,
    SingleVideo,
  }
};
</script>
