<template>
  <a :href="url" class="slide">
    <img v-if="video.thumbnail[0].url" :src="video.thumbnail[0].url" :alt="video.title" />
    <img v-else src="/" :alt="title" />
    <div class="text">
      <h3>{{ title }}</h3>
    </div>
  </a>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  data() {
    return {
    }
  },
  mounted() {

  },
  computed: {
    ...mapGetters([
      'user'
    ]),
    title: function() {

      if(this.video.tags.length) {
        let tags = [];
        this.video.tags.map((item) => { tags.push(item.title) });
        return tags.join(' · ');
      }

      return '-';
    },
    url() {

      if(!this.user.is_subscribed) {
        return '/clenstvi';
      }

      if(this.demo) {
        return `/detail-preview?video=` + this.video.id
      }

      return `/detail?video=` + this.video.id
    }
  },
  name: 'SingleVideo',
  props: ['video', 'demo']
};
</script>

<style>
.slide {
  @apply py-20 px-0 pb-0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

img {
  height: 205px;
  @apply w-full object-cover rounded shadow overflow-hidden mb-0;
  margin-bottom: 0 !important;
}

h2 {
  @apply text-black uppercase font-500 text-20 font-fira leading-tight mb-5;
}

h3 {
  @apply text-black uppercase font-500 font-fira leading-tight mb-0 text-14;
}

.text h3 {
  font-size: 14px;
  @apply mt-10;
}

.videos.light h3, .videos.light h2 {
  @apply text-white;
}
</style>