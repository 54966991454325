<template>
    <v-container fluid class="category-promo my-7" id="category">
      <v-row>
        <v-col cols="12" class="pa-0 custom-slick">
          <VueSlickCarousel v-bind="settings" v-if="promoCategories.length > 0">
            <template #prevArrow="">
              <div class="custom-arrow">
                <v-icon color="primary">mdi-chevron-left</v-icon>
              </div>
            </template>
            <template #nextArrow="">
              <div class="custom-arrow">
                <v-icon x-large color="primary">mdi-chevron-right</v-icon>
              </div>
            </template>
            <div v-for="category in promoCategories" :key="category.id">
              <div class="text-decoration-none">
                  <v-img
                      :src="category.image"
                      cover
                      gradient="to bottom right, rgba(0,0,0,.13), rgba(25,32,72,.23)"
                  >
                    <div class="d-flex justify-center align-center pb-12 fill-height flex-column text-center">

                      <div class="d-flex circle-pink">
                        <img :src="category.icon" alt="">
                      </div>

                      <h4 class="d-flex text-h5 font-weight-bold my-0 text-uppercase white--text">{{category.name}}</h4>


                    </div>
                  </v-img>
              </div>
            </div>
          </VueSlickCarousel>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import VueSlickCarousel from 'vue-slick-carousel'

    export default {
        name: 'Promo',
      components: {
        VueSlickCarousel
      },
        data () {
            return {
              promoCategories: [
                {
                  image: require('../../assets/sports/pilates.png'),
                  icon: require('../../assets/sports/pilates.svg'),
                  name: 'Pilates'
                },
                {
                  image: require('../../assets/sports/joga.png'),
                  icon: require('../../assets/sports/joga.svg'),
                  name: 'Jóga'
                },
                {
                  image: require('../../assets/sports/bodyform.png'),
                  icon: require('../../assets/sports/bodyform.svg'),
                  name: 'Bodyform'
                },
                {
                  image: require('../../assets/sports/tabata.png'),
                  icon: require('../../assets/sports/tabata.svg'),
                  name: 'HIIT / Tabata'
                },
                {
                  image: require('../../assets/sports/dance.png'),
                  icon: require('../../assets/sports/dance.svg'),
                  name: 'Dance'
                },
                {
                  image: require('../../assets/sports/func.png'),
                  icon: require('../../assets/sports/func.svg'),
                  name: 'Funkční trénink'
                },
                {
                  image: require('../../assets/sports/preg.png'),
                  icon: require('../../assets/sports/preg.svg'),
                  name: 'Těhotné a po porodu'
                },
                {
                  image: require('../../assets/sports/zada.png'),
                  icon: require('../../assets/sports/zada.svg'),
                  name: 'Zdravá záda'
                },
              ],
              settings: {
                "autoplay": true,
                "autoplayTimeout": 6000,
                "autoplayHoverPause": true,
                "dots": true,
                "focusOnSelect": true,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 4,
                "slidesToScroll": 4,
                "touchThreshold": 5,
                "lazyLoad": "ondemand",
                "arrows": true,
                "responsive": [
                  {
                    "breakpoint": 1024,
                    "settings": {
                      "slidesToShow": 3,
                      "slidesToScroll": 3,
                    }
                  },
                  {
                    "breakpoint": 600,
                    "settings": {
                      "slidesToShow": 1,
                      "slidesToScroll": 1,
                      "arrows": false
                    }
                  }
                ]
              }
            }
        },
        computed: {
            ...mapGetters([
                'allCategories'
            ]),
            imageUrl() {
                return process.env.VUE_APP_ROOT_API;
            }
        },
        methods: {
            ...mapActions([
                'addToCart'
            ])
        }
    }
</script>

<style lang="scss">
.circle-pink {
  width: 116px;
  height: 116px;
  text-align: center;
  background: #d11a6f;
  line-height: 116px;
  vertical-align: middle;
  margin: 0 auto;
  margin-bottom: 16px;
  border-radius: 50%;
  img {
    line-height: 117px;
    vertical-align: middle;
    display: inline-block;
    width: 57%;
    margin: 0 auto;
  }
}
    .category-promo {
      .v-card--reveal {
        align-items: center;
        bottom: 10px;
        left: 0;
        right: 0;
        justify-content: center;
        position: absolute;
        width: 100%;
      }
    }
    .custom-slick {
      .slick-dots {
        bottom: -40px;
        li {
          button:before {
            font-size: 12px;
          }
          &.slick-active button:before {
            color: #D11A6F;
          }
        }
      }
      .slick-prev, .slick-next {
        z-index: 5;
        height: 73px;
        &:before {
          display: none;
        }
        .v-icon {
          font-size: 73px !important;
        }
      }
      .slick-prev {
        left: 0;
      }
      .slick-next {
        right: 40px;
      }
    }
</style>
