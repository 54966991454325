<template>
  <v-container>
    <div>
      <v-skeleton-loader type="article" v-if="loadingCategories || !currentProduct"></v-skeleton-loader>
      <div v-else>
        <v-row class="product-detail">
          <v-col cols="12" sm="5" style="margin-top: -200px">
            <v-card tile elevation="0" color="primary" dark min-width="230px">
              <v-card-text class="white--text">
                <h2 class="product-title">{{ currentProduct.name }}</h2>
                <p class="price font-weight-thin mt-10 mb-6">{{ currentProduct.amountWithVat }} Kč</p>
                <v-btn color="white" class="black--text" tile block depressed @click="addToCart({product: currentProduct, qty: qty})">Přidat do košíku</v-btn>
              </v-card-text>
            </v-card>
            <v-breadcrumbs
                :items="breadcrumbs"
            ></v-breadcrumbs>
          </v-col>
          <v-col cols="12" sm="7" class="pt-1">
            <p>
              <v-chip
                  color="primary"
                  outlined
                  label
                  class="mr-2"
                  v-for="(item,index) in productSpecials" :key="index"
              >
                {{item}}
              </v-chip>
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="8">
            <h4 class="product-description-title font-weight-light">O lekci</h4>
            <div class="custom-divider primary mb-4 mt-2"></div>
            <p v-html="currentProduct.description" class="product-description mb-6"></p>
          </v-col>
          <v-col cols="12" sm="4">
            <v-img :src="getProductImage(currentProduct.imageUrl)"></v-img>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import {getProductImage} from '@/Helper'

    export default {
        name: 'ProductDetail',
        computed: {
          ...mapGetters([
            'currentProduct',
            'currentCategory',
            'loadingCategories',
              'allCategories'
          ]),
          productSpecials() {
            return this.currentProduct.alias ? this.currentProduct.shortDescription.split(' | ') : [];
          },
          breadcrumbs() {
            let breadcrumbs = [];

            breadcrumbs.push(
                {
                  text: 'Domů',
                  disabled: false,
                  to: '/',
                }
            );

            if (this.currentCategoryProduct) {
              breadcrumbs.push({
                text: this.currentProduct.productCategoryName,
                disabled: false,
                to: '/category/' + this.currentCategoryProduct,
              })
            }

            breadcrumbs.push(
                {
                  text: this.currentProduct.name,
                  disabled: true,
                  to: '',
                },
            )

            return breadcrumbs;
          },
          currentCategoryProduct() {
            let currentProductCategory = this.allCategories.filter(item => item.id === this.currentProduct.productCategoryId);
            if (currentProductCategory && currentProductCategory[0]) {
              return currentProductCategory[0]['alias'];
            }
            return null
          }
        },
      mixins: [getProductImage],

        data () {
            return {
                qty: 1
            }
        },
        methods: {
            ...mapActions([
                'addToCart'
            ]),
            backButton() {
                this.$router.push('/');
            }
        }
    }
</script>

<style lang="scss">
.custom-divider {
  width: 50px;
  height: 5px
}
.product-description-title {
  font-size: 30px;
}
    .product-detail {
      .product-title {
        font-size: 25px;
        line-height: 1.5;
      }
        .v-toolbar__content {
            padding-left: 0;
        }
        .product-description {
            span {
                display: block;
                color: #777;
                &:first-child {
                    margin-bottom: 20px;
                    color: #000000;
                }
            }
        }
        .price {
            font-size: 42px;
        }
        .vue-numeric-input {
            input {
                outline: none;
                height: 100%;
            }
            .btn {
                background: transparent;
                box-shadow: none;
                border: none;
                &:hover {
                    background: #ff9400;
                }
            }
        }
    }
</style>
