<template>
    <v-container>
        <v-layout class="mb-7" v-if="orderId">
            <v-flex text-center v-if="!orderId.resultText">
                <h2 class="mt-7 mb-3">Vaše objednávka byla úspěšná!</h2>
                <p>Číslo objednávky: {{orderId.orderNumber}}</p>
                <div v-if="orderId.bankAccount" class="mb-3">
                    <div>Bankovní spojení: <strong>{{ orderId.bankAccount}}</strong></div>
                    <div>Částka: <strong>{{ orderId.totalWithVat}} Kč</strong></div>
                    <div>Variabilní symbol: <strong>{{ orderId.bankVariableSymbol}}</strong></div>
                </div>
                <div v-if="orderId.spaidQR" class="mb-3">
                    <img :src="qrCode" />
                </div>
                <div v-if="$vuetify.breakpoint.xsOnly && orderId.spaidFile" class="my-3 mb-5">
                    <div class="my-3">Pro platbu přes bankovní mobilní aplikaci můžete využít tlačítko níže.</div>
                    <v-btn color="primary" depressed small tile :href="orderId.spaidFile">Platba přes aplikaci</v-btn>
                </div>
                <p>O stavu objednávky budete notifikováni na email.</p>
                <v-btn color="primary" depressed tile @click="$router.push('/')">Zpět do obchodu</v-btn>
            </v-flex>
            <v-flex text-center v-else>
                <h2 class="mt-7 mb-3">Během zpracování došlo k chybě.</h2>
                <p>Číslo objednávky: {{orderId.orderNumber}}</p>
                <p>Chyba: <span v-text="orderId.resultText"></span></p>
                <v-btn color="primary" depressed tile @click="$router.push('/')">Zpět do obchodu</v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: 'CheckoutSuccess',
        data () {
            return {
                qrCode: null
            }
        },
        mounted() {
            if (!this.orderId) {
                this.$router.push('/');
            } else {
                if (this.orderId.spaidQR) {
                    this.fetchQRCode(this.orderId.spaidQR)
                }
            }
        },
        computed: {
            ...mapGetters([
                'orderId'
            ])
        },
        methods: {
            async fetchQRCode(code) {
                let response = await fetch(code);

                if (response.ok) {
                    let json = await response.text();
                    this.qrCode = json.slice(1,-1);
                } else {
                    console.log(response.status);
                }
            }
        }
    }
</script>
